import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { Table, Button, FormControl } from 'react-bootstrap'; // Import necessary components from Bootstrap

const ApprovedWithdraw = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 100; // Set the number of rows per page

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleSort = (field) => {
        setSortField(field);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    const filteredData = data.filter(user =>
        user.id.toString().includes(searchTerm.toLowerCase()) ||
        user.account_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.account_number.toString().includes(searchTerm.toLowerCase())
    );

    const sortedData = [...filteredData].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const paginatedData = sortedData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    useEffect(() => {
        const fetchApprovedWithdrawalRequests = async () => {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/withdrawalRequestsApproved`);
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                } else {
                    console.error('Data is not an array:', response.data.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchApprovedWithdrawalRequests();
    }, []);

    const rejectRequest = (userId, requestId) => {
        if (!userId || !requestId) {
            console.error('User ID and request ID are required');
            return;
        }

        axios.post(`${import.meta.env.VITE_API_BASE_URL}/reject-withdrawal`, { userId, requestId })
            .then(response => {
                const updatedData = data.filter(item => item.id !== requestId);
                setData(updatedData);
            })
            .catch(error => {
                console.error('Error details:', error.response ? error.response.data : error.message);
            });
    };

    const formatDateAndTime = (dateString) => {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(',', '');
    }

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    }

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    }

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <div className="listContainer">
                    <FormControl
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearch}
                        className="mb-3"
                    />
                    {isLoading ? (
                        <div className="loader-bar"></div>
                    ) : (
                        <>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th className='bg-primary text-light' onClick={() => handleSort('amount')}>Amount</th>
                                        <th className='bg-primary text-light' onClick={() => handleSort('account_name')}>Account Name</th>
                                        <th className='bg-primary text-light' onClick={() => handleSort('account_number')}>Account Number</th>
                                        <th className='bg-primary text-light' onClick={() => handleSort('bank_name')}>Bank Name</th>
                                        <th className='bg-primary text-light'>Action</th>
                                        <th className='bg-primary text-light' onClick={() => handleSort('request_date')}>Request Date</th>
                                        <th className='bg-primary text-light' onClick={() => handleSort('approved_time')}>Approve Time</th>
                                        <th className='bg-primary text-light' onClick={() => handleSort('user_id')}>User ID</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData.map(row => (
                                        <tr key={row.id}>
                                            <td>{row.amount}</td>
                                            <td>{row.account_name}</td>
                                            <td>{row.account_number}</td>
                                            <td>{row.bank_name}</td>
                                            <td>
                                                <button className='btn btn-danger btn-sm ' onClick={() => rejectRequest(row.user_id, row.id)}>Reject</button>
                                            </td>
                                            <td>{formatDateAndTime(row.request_date)}</td>
                                            <td>{formatDateAndTime(row.approved_time)}</td>
                                            <td>{row.user_id}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <div className="pagination-buttons">
                                <Button
                                    onClick={handlePrevPage}
                                    disabled={currentPage === 1}
                                    className="me-2"
                                >
                                    Previous
                                </Button>
                                <Button
                                    onClick={handleNextPage}
                                    disabled={currentPage * rowsPerPage >= sortedData.length}
                                >
                                    Next
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ApprovedWithdraw;
