import { useContext, useEffect, useState } from "react";
import "./Waiting.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext/UserContext";
import Logo from './imgs/logo.svg';
const Waiting = () => {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { paymentOk, approved, isRejected, fetchUserData ,logout} =
    useContext(UserContext);

  useEffect(() => {
    fetchUserData().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      if (isRejected === 1 || paymentOk === 0) {
        navigate("/payment");
      } else if (approved === 1) {
        navigate("/walletpage");
      } else if (paymentOk === 1 && approved === 0) {
        navigate("/waiting");
      }
    }
  }, [isRejected, approved, paymentOk, navigate, loading]);

  if (loading) {
    return (
      <div className="loader__container">
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
      </div>
    );
  }

  return (

    <>
    <div className="logo  bg-gradient-to-r from-purple-500 p-3 to-indigo-400 d-flex justify-content-center">
      <img src={Logo} width={150} alt="logo" />
    </div>
    <div className="vh-100  bg-gradient-to-r from-purple-500 p-3 to-indigo-400">
      <div className="overlay-text">
        <h1 className="text-pending mt-5">Your payment is being processed.</h1>
        <p className="p-3 text-pending1">in a few minutes you will be redirected</p>
      </div>
      <div className="loader-d">
        <div className="loader__balls">
          <div className="loader__balls__group">
            <div className="ball item1"></div>
            <div className="ball item1"></div>
            <div className="ball item1"></div>
          </div>
          <div className="loader__balls__group">
            <div className="ball item2"></div>
            <div className="ball item2"></div>
            <div className="ball item2"></div>
          </div>
          <div className="loader__balls__group">
            <div className="ball item3"></div>
            <div className="ball item3"></div>
            <div className="ball item3"></div>
          </div>
        </div>
        
      </div>
<button className="logout-btn btn btn-danger mt-3 w-100 rounded-5 btn-sm" onClick={logout}>Log Out</button>
    </div>
    </>

  );
};

export default Waiting;
