import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { Button, Table, Form, Spinner } from 'react-bootstrap';

const EasyPaisa = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);  
    const [searchTerm, setSearchTerm] = useState('');
    const [loadingApproveUsers, setLoadingApproveUsers] = useState([]);
    const [loadingRejectUsers, setLoadingRejectUsers] = useState([]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }

    const filteredData = data.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.id.toString().includes(searchTerm.toLowerCase()) ||
        user.trx_id.toString().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/EasypaisaUsers`, {
            params: {
                type: 0
            }
        })
        .then(response => {
            if (response.data && response.data.approvedUsers) {
                setData(response.data.approvedUsers);
            }
        })
        .catch(error => {
            console.error("There was an error fetching the approved users!", error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    const handleApprove = async (userId) => {
        if (loadingApproveUsers.includes(userId)) return;

        setLoadingApproveUsers((prev) => [...prev, userId]);

        try {
            await axios.put(`${import.meta.env.VITE_API_BASE_URL}/approveUser/${userId}`, { approved: 1, approved_at: new Date() });

            const updatedData = data.filter(user => user.id !== userId);
            setData(updatedData);

           
        } catch (error) {
            console.error("There was an error approving the user!", error);
        } finally {
            setLoadingApproveUsers((prev) => prev.filter(id => id !== userId));
        }
    };

    const handleReject = async (userId) => {
        if (loadingRejectUsers.includes(userId)) return;

        setLoadingRejectUsers((prev) => [...prev, userId]);

        try {
            await axios.put(`${import.meta.env.VITE_API_BASE_URL}/rejectUser/${userId}`);

            const updatedData = data.filter(user => user.id !== userId);
            setData(updatedData);
        } catch (error) {
            console.error("There was an error rejecting the user!", error);
        } finally {
            setLoadingRejectUsers((prev) => prev.filter(id => id !== userId));
        }
    };

    return (
        <>
            
        
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <Form.Control
                            type="text"
                            placeholder="Search..."
                            onChange={handleSearch}
                            className="mb-3"
                        />
                        {isLoading ? (
                            <>
                             <div className="d-flex justify-content-center align-items-center">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                            </>
                         


                        ) : (
                            <Table striped bordered hover responsive>
                                <thead className="table-primary">
                                    <tr>
                                        <th className='bg-primary text-white'>ID</th>
                                        <th className='bg-primary text-white'>TRX ID</th>
                                        <th className='bg-primary text-white'>Approve</th>
                                        <th className='bg-primary text-white'>Reject</th>
                                        <th className='bg-primary text-white'>Sender Number</th>
                                        <th className='bg-primary text-white'>Sender Name</th>
                                        <th className='bg-primary text-white'>ReferrBy</th>
                                        <th className='bg-primary text-white'>Email</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map((user) => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.trx_id}</td>
                                            <td>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    onClick={() => handleApprove(user.id)}
                                                    disabled={loadingApproveUsers.includes(user.id)}
                                                >
                                                    {loadingApproveUsers.includes(user.id) ? 'Processing...' : 'Approve'}
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    size="sm"
                                                    onClick={() => handleReject(user.id)}
                                                    disabled={loadingRejectUsers.includes(user.id)}
                                                >
                                                    {loadingRejectUsers.includes(user.id) ? 'Processing...' : 'Reject'}
                                                </Button>
                                            </td>
                                            <td>{user.sender_number}</td>
                                            <td>{user.sender_name}</td>
                                            <td>{user.refer_by}</td>
                                            <td>{user.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default EasyPaisa;