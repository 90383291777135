import "./home.scss";
import AdminLogin from "../AdminLogin";

const HomePage = () => {
  return (
    <div className="home">
   
      <div className="homeContainer">
        
       
        <div className="listContainer">
<AdminLogin/>
     </div>
      </div>
    </div>
  );
};

export default HomePage;
