import React, { useEffect, useState } from 'react';
import Sidebar from "../SideBarSection/Sidebar";
import DataTable from 'react-data-table-component';
import axios from 'axios';

const ApprovedUsers = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/rejectedUsers`)
            .then(response => {
                if (response.data && response.data.approvedUsers) {
                    setData(response.data.approvedUsers);
                }
            })
            .catch(error => {
                console.error("Error fetching approved users", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = data.filter(user => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return (
            (user.name && user.name.toLowerCase().includes(lowerCaseSearchTerm)) ||
            (user.email && user.email.toLowerCase().includes(lowerCaseSearchTerm)) ||
            (user.id && user.id.toString().includes(lowerCaseSearchTerm)) ||
            (user.trx_id && user.trx_id.toString().includes(lowerCaseSearchTerm))
        );
    });

    const handleClearOldRecords = () => {
        if (window.confirm("Delete 7 Days Old Records?")) {
            axios.delete(`${import.meta.env.VITE_API_BASE_URL}/delete-old-rejected-users`)
                .then(response => {
                    alert(response.data.message || "Old records deleted");
                    refreshData();
                })
                .catch(error => console.error("Error deleting records", error));
        }
    };

    const handleClearRejectedUsers = () => {
        if (window.confirm("Delete all rejected users?")) {
            axios.delete(`${import.meta.env.VITE_API_BASE_URL}/delete-rejected-users`)
                .then(response => {
                    alert(response.data.message || "Rejected users deleted");
                    refreshData();
                })
                .catch(error => console.error("Error deleting users", error));
        }
    };

    const refreshData = () => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/rejectedUsers`)
            .then(response => setData(response.data.approvedUsers || []));
    };

    const handleApprove = (userId) => {
        axios.put(`${import.meta.env.VITE_API_BASE_URL}/approveUser/${userId}`, { approved: 1 })
            .then(() => {
                setData(data.filter(user => user.id !== userId));
            })
            .catch(error => console.error("Error approving user", error));
    };

    const columns = [
        { name: 'ID', selector: row => row.id, sortable: true },
        { name: 'Name', selector: row => row.name, sortable: true },
        { name: 'Email', selector: row => row.email, sortable: true },
        { name: 'TRX ID', selector: row => row.trx_id, sortable: true },
        { name: 'Complete Address', selector: row => row.completeAddress },
        { name: 'City', selector: row => row.city },
        { name: 'Balance', selector: row => row.balance },
        { name: 'Approve', cell: row => (
            <button
                className="bg-green-500 text-white py-1 px-2 rounded hover:bg-green-600"
                onClick={() => handleApprove(row.id)}
            >
                Approve
            </button>
        )}
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#3B82F6', // Tailwind color
                color: '#FFFFFF',
                fontSize: '14px',
                paddingLeft: '8px',
            },
        },
        rows: {
            style: {
                backgroundColor: '#F3F4F6', // Tailwind gray-100
                border: '1px solid #FFFFFF',
            },
        },
        cells: {
            style: {
                border: '1px solid #FFFFFF',
            },
        },
    };

    return (
        <div className="flex">
            <Sidebar />
            <div className="w-full p-4">
                <div className="mb-4 flex flex-col md:flex-row items-center">
                    <input
                        className="w-full md:w-auto p-2 mb-2 md:mb-0 border border-gray-300 rounded shadow-sm"
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearch}
                    />
                    <div className="flex space-x-2">
                        <button
                            className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600"
                            onClick={handleClearOldRecords}
                        >
                            Clear 7 Days Old Record
                        </button>
                        <button
                            className="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600"
                            onClick={handleClearRejectedUsers}
                        >
                            Clear All Records
                        </button>
                    </div>
                </div>
                {!isLoading && filteredData.length === 0 ? (
                    <p>No data available</p>
                ) : (
                    <DataTable
                        title="Rejected Users"
                        columns={columns}
                        data={filteredData}
                        pagination
                        highlightOnHover
                        customStyles={customStyles}
                    />
                )}
            </div>
        </div>
    );
};

export default ApprovedUsers;
