import React, { useState } from 'react';
import GetWithdraw from './GetWithdraw'; // Assuming this is the correct path
import WithdrawalHistory from './WithdrwaHistory';
import TopBar from '../TopBar';
import { Tabs, Tab } from 'react-bootstrap'; // Using React-Bootstrap for tabs
import './WithdrawalPage.css'; // Import the CSS file

const WithdrawalPage = () => {
    const [activeTab, setActiveTab] = useState('withdraw'); // Set the default tab

    return (
        <div className="withdrawal-page bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900 ">
            <TopBar />

            <div className="content-container rounded-lg">
                <Tabs
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key)}
                    className="mb-3 custom-tabs flex justify-around w-full"
                >
                    <Tab eventKey="withdraw" title="Withdraw">
                        <GetWithdraw />
                    </Tab>
                    <Tab eventKey="history" title="Withdraw History">
                        <WithdrawalHistory />
                    </Tab>
                </Tabs>
            </div>
        </div> 
    );
}

export default WithdrawalPage;
