 import React, { useEffect, useState } from 'react';
import '../Homepage/home.scss';
import Sidebar from '../SideBarSection/Sidebar';
import axios from 'axios';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Table } from 'react-bootstrap';

const Products = () => {
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [editingProduct, setEditingProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = () => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/products`)
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => console.error('Error:', error))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const formatDateAndTime = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    }

    const handleDelete = async (id) => {
        try {
            await axios.delete(`${import.meta.env.VITE_API_BASE_URL}/products/${id}`);
            fetchData();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const product = {
            description: event.target.description.value,
            link: event.target.link.value,
            imgLink: event.target.imgLink.value
        };
        try {
            if (editingProduct) {
                await axios.put(`${import.meta.env.VITE_API_BASE_URL}/products/${editingProduct.id}`, product);
            } else {
                await axios.post(`${import.meta.env.VITE_API_BASE_URL}/products`, product);
            }
            setShowModal(false);
            setEditingProduct(null);
            fetchData(); // Fetch updated data after adding or updating a product
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <Button
                    style={{
                        backgroundColor: 'purple',
                        color: 'white',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        margin: '20px',
                    }}
                    onClick={() => {
                        setEditingProduct(null);
                        setShowModal(true);
                    }}
                >
                    Add Product
                </Button>
                <Dialog open={showModal} onClose={() => setShowModal(false)}>
                    <DialogTitle>
                        {editingProduct ? 'Edit Product' : 'Add Product'}
                    </DialogTitle>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="Description"
                                type="text"
                                name="description"
                                defaultValue={editingProduct ? editingProduct.description : ''}
                                required
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Link"
                                type="text"
                                name="link"
                                defaultValue={editingProduct ? editingProduct.link : ''}
                                required
                                fullWidth
                                margin="normal"
                            />
                            <TextField
                                label="Img Link"
                                type="text"
                                name="imgLink"
                                defaultValue={editingProduct ? editingProduct.imgLink : ''}
                                required
                                fullWidth
                                margin="normal"
                            />
                            <DialogActions>
                                <Button
                                    type="submit"
                                    style={{ backgroundColor: 'purple', color: 'white' }}
                                >
                                    {editingProduct ? 'Update' : 'Add'} Product
                                </Button>
                                <Button onClick={() => setShowModal(false)} color="secondary">
                                    Cancel
                                </Button>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
                <div className="listContainer">
                    {isLoading ? (
                        <div className="loader-bar"></div>
                    ) : (
                        <Table striped bordered hover responsive>
                            <thead className="bg-primary text-white">
                                <tr>
                                    <th  className='bg-primary text-light' >Link</th>
                                    <th  className='bg-primary text-light' >Img Link</th>
                                    <th  className='bg-primary text-light' >Description</th>
                                    <th  className='bg-primary text-light' >Actions</th>
                                    <th  className='bg-primary text-light' >Created At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td>{row.link}</td>
                                        <td>{row.imgLink}</td>
                                        <td>{row.description}</td>
                                        <td>
                                            <Button
                                                onClick={() => {
                                                    setEditingProduct(row);
                                                    setShowModal(true);
                                                }}
                                                style={{
                                                    backgroundColor: 'blue',
                                                    color: 'white',
                                                    marginRight: '5px',
                                                    padding: '5px 10px',
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                onClick={() => handleDelete(row.id)}
                                                style={{
                                                    backgroundColor: 'red',
                                                    color: 'white',
                                                    padding: '5px 10px',
                                                    borderRadius: '5px',
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                        <td>{formatDateAndTime(row.created_at)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Products;
