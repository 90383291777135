import React, { useContext, useEffect, useState } from 'react';
import './walletpage.css';
import { UserContext } from '../UserContext/UserContext';
import cardlogo from '../imgs/card.png';
import { SlUser } from "react-icons/sl";
import { CiIndent ,CiGift,CiCreditCard1} from "react-icons/ci";
import { PiWalletThin } from "react-icons/pi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

import { useNavigate } from 'react-router-dom';
import { BsCurrencyDollar } from "react-icons/bs";
import { GrBundle } from "react-icons/gr";
import Slider from './Slider';
import TopBar from '../TopBar';
import axios from 'axios';

const removeTrailingZeros = (num) => {
  return num.toString().replace(/\.?0+$/, "");
};

const WalletPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { userData, fetchUserData } = useContext(UserContext);
  const [balance, setBalance] = useState(0);
  const [name, setName] = useState('John Doe');
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState();
  const [offer, setOffer] = useState('');

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/get-offer`);
        setOffer(response.data.offer);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOffer();
  }, []);
  console.log(offer);

  useEffect(() => {
    if (userData) {
      const updatedBalance = userData.balance ?? 0;
      setName(userData.name ?? 'John Doe');
      setBalance(updatedBalance);
      setProfilePicture(userData.profile_picture);

    }
  }, [userData]);

  useEffect(() => {
    fetchUserData().finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
      <div className="loader__container mt-20">
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
      </div>
    )
  }



  const handlBonusNavigation = () => {
    navigate('/bonus');
  };
  const handlSalaryNavigation = () => {
    navigate('/salary');
  };
  const cards = [
    { id: 1, name: 'Tasks', icon: <CiIndent />, onClick: () => navigate('/tasks') },
    { id: 4, name: 'Bonus', icon: < CiGift />, onClick: handlBonusNavigation },
    { id: 2, name: 'Wallet', icon: <PiWalletThin />, onClick: () => navigate('/getwithdraw') },

    { id: 3, name: 'Salary', icon: <CiCreditCard1 />, onClick: handlSalaryNavigation },

    { id: 5, name: 'Team', icon: <AiOutlineUsergroupAdd />, onClick: () => navigate('/team') },
    { id: 6, name: 'Profile', icon: <SlUser />, onClick: () => navigate('/setting') },

  ];
  const fallBackImage = 'https://metroearn.com/dp.png';

  return (
    <>
      <div className="relative min-h-screen "> {/* Background wrapper for the entire page */}

        <div className="absolute inset-0 bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900 "></div>

        <div className="relative z-10 p-3">
          <TopBar />

          <div className="relative">
            {/* Content container with z-10 to avoid blur */}
            <div className="relative z-10 mx-auto">
              <div className="rounded-xl shadow-lg overflow-hidden">
                <div className="p-6 flex items-center justify-between  p-wrapper shadow shadow-lg border border-gray-200 rounded-xl bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900  ">
                  <img
                    className="w-24 h-24 rounded-full object-cover border-4 border-gray-200"
                    src={profilePicture ? `${import.meta.env.VITE_API_BASE_URL}/${profilePicture}` || fallBackImage : fallBackImage}
                    alt="User Profile"
                  />
                  <div className="mr-4 mr-5" >
                    <h2 className=" font-bold text-white uppercase tracking-wider">
                      {name}
                    </h2>
                    <p className="mt-2 text-2xl font-bold text-white flex items-center gap-1">
                      <span className='text-success'>$</span>{removeTrailingZeros(balance)}
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Grid of circular cards */}
          <div className="grid grid-cols-6 gap-3 mt-4">
            {cards.map((card) => (
              <div key={card.id} className="flex flex-col items-center">
                {/* Circular card */}
                <div
                  className="menu-size relative z-10 bg-white/30 backdrop-blur-lg rounded-full shadow-lg flex items-center justify-center cursor-pointer hover:bg-white/40 transition-all"
                  onClick={card.onClick}
                >
                  <div className=" text-white text-xl ">{card.icon}</div>
                </div>
                {/* Label under the circle */}
                <div className="mt-2 text-sm font-medium text-white text-center">
                  {card.name}
                </div>
              </div>
            ))}
          </div>

          <div className="slider-wraper mt-4">
            <Slider />

          </div>
          <div className="heading-main mt-1 z-10">
            <div className="heading-container">
              <p className="heading-line p-0 m-0">
                {offer
                }
              </p>
            </div>
          </div>

          <div onClick={() => navigate('/teamdetails')} className="invite-wraper shadow-lg rounded-xl flex justify-between items-center px-5 mt-1">
            <div className="icone">
              <img src={cardlogo} width={40} height={30} alt="" />
            </div>

            <div className="details mr-5" >

              <p className=' text-white cursor-pointer text-2xl'>Earn More </p>

            </div>



          </div>


        </div>
      </div>

    </>
  );
};

export default WalletPage;
