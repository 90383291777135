import React, { useContext } from 'react';
import './TopBar.css'; 
import { UserContext } from './UserContext/UserContext';
import { useNavigate } from 'react-router-dom';
import svgLogo from './imgs/logo.svg';
const TopBar = () => {
    const {logout} = useContext(UserContext);
const navigate = useNavigate();
  return (
    <div className="topbar-container">
      <div className="topbar-left " onClick={() => navigate('/walletpage')}>
      
      </div>

      <div className="ml-10 logo-cont" onClick={() => navigate('/walletpage')}>
            <img src={svgLogo} alt="logo" width={100} className="logo" />          
          </div>  

      <div className="topbar-right">
      <button onClick={logout} className="text-white bg-red-600 hover:bg-red-600 font-sm rounded-lg text-sm px-3 py-1 text-center">
                Sign Out
              </button>
      </div>
    </div>
  );
};

export default TopBar;
