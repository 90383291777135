import { useContext, useEffect, useState } from 'react';
import './styles.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from './UserContext/UserContext';
import Logo from './imgs/logo.svg';
export const Login = () => {
  const { paymentOk, isAuthCheckComplete, fetchUserData, isRejected, isAuthenticated, approved } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const Navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${import.meta.env.VITE_API_BASE_URL}`, { withCredentials: true });
        if (res.data.Status === '!valid') {
          Navigate('/');
        }
        setLoading(false);
      } catch (err) {
        console.error('Error: ', err);
        setLoading(false);
      }
    };

    fetchData();
  }, [Navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/login`, values, { withCredentials: true });
      if (res.data.Status === "Success") {
        await fetchUserData();
      } else {
        setError(res.data.Error);
      }
    } catch (err) {
      console.error("Error: ", err);
    }

    setLoading(false);
    setValues({ email: '', password: '' });
  };

  useEffect(() => {
    if (isAuthenticated && isAuthCheckComplete) {
      if (isRejected || paymentOk === 0) {
        Navigate('/agreement');
      } else if (approved === 1 && paymentOk === 1) {
        Navigate('/walletpage');
      } else if (paymentOk === 1 && approved === 0) {
        Navigate('/waiting');
      }
    }
  }, [isAuthenticated, isAuthCheckComplete, isRejected, approved, paymentOk, Navigate]);

  const Spinner = () => (
    <div className="spinner-border text-light sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );

  return (
     <>
     
      <div className="bg-gradient-to-r from-purple-500  to-indigo-400  text w-full h-1/2 lg:h-auto flex flex-col items-center justify-center text-white vh-100 p-6">
        <img className='mb-10' src={Logo} width={200} alt="" />
        <div className="login-form w-full max-w-md">
          <form onSubmit={handleSubmit} className="form-style">
            <div className="mb-6">
              <p className="text-2xl font-bold mb-3">Login</p>
              {error && <p className="text-red-500">{error}</p>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-medium mb-2">Email</label>
              <input
                type="email"
                className="w-full p-2 border border-gray-300 rounded bg-transparent"
                id="email"
                name="email"
                required
                onChange={(e) => setValues({ ...values, email: e.target.value })}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm font-medium mb-2">Password</label>
              <input
                type="password"
                className="w-full p-2 border border-gray-300 rounded bg-transparent"
                id="password"
                name="password"
                required
                onChange={(e) => setValues({ ...values, password: e.target.value })}
              />
            </div>
            
            <div className="flex justify-center mb-4">
              <button
                type="submit"
                className="bg-blue-600 w-full text-white px-4 py-2 rounded-5 hover:bg-blue-700 disabled:opacity-50"
                disabled={loading}
              >
                {loading ? <Spinner /> : 'Login'}
              </button>
            </div>
          </form>
        </div>
      </div>  
       
      </>
    );
};
