import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import './DailyTask.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from './UserContext/UserContext';
import TopBar from './TopBar';

const DailyTasks = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [disabledButtons, setDisabledButtons] = useState({});
    const { Userid } = useContext(UserContext);
    const [productReward, setProductReward] = useState(0);

    useEffect(() => {
        const fetchClickedProducts = async () => {
            try {
                const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/fetchClickedProducts`, {
                    withCredentials: true,
                });

                if (response.data.status === 'success') {
                    const clickedProducts = response.data.products;
                    const todayWallet = response.data.today_wallet;

                    setProducts(clickedProducts);
                    setProductReward(todayWallet);

                    // Disable buttons based on last clicked date
                    const today = new Date().toISOString().split('T')[0];
                    const updatedDisabledButtons = {};

                    clickedProducts.forEach(product => {
                        // Check if last_clicked is today
                        if (product.last_clicked) {
                            const lastClickedDate = new Date(product.last_clicked).toISOString().split('T')[0];
                            updatedDisabledButtons[product.id] = (lastClickedDate === today);
                        }
                    });

                    setDisabledButtons(updatedDisabledButtons);
                } else {
                    throw new Error(response.data.error);
                }
            } catch (error) {
                console.error("Error fetching clicked products!", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchClickedProducts();
    }, [Userid]);

    const handleButtonClick = async (productId, link) => {
        setDisabledButtons(prev => ({ ...prev, [productId]: true }));

        try {
            const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/updateBalance`, {
                productId,
                reward: productReward,
            }, { withCredentials: true });

            if (response.data.status === 'success') {
                window.open(link, '_blank');
            } else {
                toast.error(response.data.error);
            }
        } catch (error) {
            console.error("Error updating the balance!", error);
        } finally {
            // No need to reset the disabled state here; it should remain true if already clicked
        }
    };

    if (error) return <div>Error loading products</div>;

    return (
        <>
            <div className="p-3 relative inset-0 bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900">
                <TopBar />
                <div className="m3">
                    {loading ? (
                        <div className="loader__container">
                            <div className="loader__circle"></div>
                            <div className="loader__circle"></div>
                            <div className="loader__circle"></div>
                            <div className="loader__circle"></div>
                            <div className="loader__circle"></div>
                        </div>
                    ) : (
                        products.map((product) => (
                            <div key={product.id} className="notificationCard mx-auto mb-4 bg-whit backdrop-blur-md border border-gray-300 rounded-lg shadow-md overflow-hidden max-w-md">
                                <div className="h-40 flex flex-col justify-center gap-2 rounded-lg p-2 overflow-hidden cursor-pointer">
                                    <div className="flex gap-2">
                                        <img className="bg-neutral-500 object-cover object-center rounded-lg shadow w-24 h-24 shrink-0" alt="" src={product.imgLink} />
                                        <div className="flex flex-col">
                                            <span className="font-bold text-stone-100 italic">Task <span className='text-warning'>{product.id}</span></span>
                                            <p className="line-clamp-3 text-stone-300">
                                                {product.description}
                                            </p>
                                        </div>
                                    </div>
                                    <button
                                        className={`w-full p-2 rounded-lg text-white font-bold transition-colors ${disabledButtons[product.id] ? 'bg-fuchsia-950 hover:bg-indigo-600' : 'bg-fuchsia-600 hover:bg-fuchsia-600'}`}
                                        onClick={() => handleButtonClick(product.id, product.link)}
                                        disabled={disabledButtons[product.id]}
                                    >
                                        {disabledButtons[product.id] ? 'Completed' : 'Complete'}
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                    <ToastContainer position="top-center" />
                </div>
            </div>
        </>
    );
};

export default DailyTasks;
