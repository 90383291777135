import axios from "axios";
import "./styles.css";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext/UserContext";
import Logo from './imgs/logo.svg';

const removeTralingZero = (num) => {
  // Convert the number to a fixed decimal string (2 decimals) and remove any trailing zeros
  return parseFloat(num).toFixed(2).replace(/(\.00$|(\.[1-9]*)0+$)/, "$2");
}

const Payment = ({ isRejected, Userid }) => {
  const [accName, setAccName] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { newId, paymentOk,logout } = useContext(UserContext);
  const [account, setAccount] = useState(null);
  const [trx_id, setTrxId] = useState("");
  const [sender_name, setSenderName] = useState("");
  const [sender_number, setSenderPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [fee, setFee] = useState(0);
  const [uid, setUid] = useState(null);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_BASE_URL}/getUserIdFromSession`, { withCredentials: true })
      .then(response => {
        if (response.data.userId) {
          setUid(response.data.userId);
        } else {
          console.log('No user ID found in session');
        }
      })
      .catch(error => {
        console.error('Error fetching user ID from session:', error);
        navigate('/');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!uid) {
      navigate('/');
    }

    if (paymentOk === 0) {
      navigate('/payment');
    }

  }, [uid, paymentOk, navigate]);

  useEffect(() => {
    const apiEndpoint = `${import.meta.env.VITE_API_BASE_URL}/receive-accounts`;
    const feeEndpoint = `${import.meta.env.VITE_API_BASE_URL}/get-fee`;

    setLoading(true);

    fetch(apiEndpoint)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          setAccName(data.account.account_name);
          setAccount(data.account.account_number);

          fetch(feeEndpoint)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
              }
              return response.json();
            })
            .then(feeData => {
              if (feeData.success) {
                setFee(feeData);
              } else {
                setError(feeData.message);
              }
            })
            .catch(error => {
              console.error('Error fetching fee:', error);
              setError(error.message);
            });
        } else {
          setError(data.message);
        }
        setLoading(false);
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        setError(error.message);
      });
  }, []);

  const id = localStorage.getItem('Userid');
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    // Validate phone number
    if (sender_number.length !== 11 || !sender_number.startsWith('03')) {
      setError('Invalid Phone Number!');
      return;
    }
  
    // Validate transaction ID
    if (trx_id.length < 11 || trx_id.length > 12) {
      setError('Invalid Transaction ID!');
      return;
    }
  
    // Validate sender name
    if (sender_name.length < 1 || /\d/.test(sender_name)) {
      setError('Invalid Name!');
      return;
    }
  
    setLoading(true);
  
    const paymentData = {
      trx_id,
      sender_name,
      sender_number,
      id: Userid || id,
    };
  
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/payment`, paymentData);
      
      // If payment was successful, navigate to waiting page
      if (response.data.status === 'success') {
        setLoading(false);
        navigate('/waiting');
      } else {
        setError('Failed to send payment data');
        setLoading(false);
        console.error('Error from server:', response.data.error);
      }
    } catch (error) {
      // Handle the case where the transaction ID is already in use
      if (error.response && error.response.status === 400) {
        alert('Transaction ID already in use. Please use a different ID.');
      } else {
        setError(error.response ? error.response.data.error : error.message);
      }
      setLoading(false);
    }
  };
  

  if (loading) {
    return (
      <div className="loader__container">
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
      </div>
    );
  }
  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>

      <div className="main-pay shadow  p-4 d-flex flex-column align-items-center justify-content-center  rounded bg-gradient-to-r from-purple-500   to-indigo-400 ">

      <img  src={Logo} width={200} alt="" />

      <p className="text-center text-3xl text-white  mt-3 font-bold w-100">Joining Fee {fee.feeInPkr}<span className="text-sm text-warning">PKR</span></p>
      <p className="text-center  text-white mt-3  w-100">Your Selected Country is Pakistan 🇵🇰</p>
      <p className="text-center mt-3 text-white  w-100">USD Rate {removeTralingZero(fee.feeInPkr/fee.fee)} * Fee {removeTralingZero(fee.fee)}$ </p>
      <p className="text-center mb-3  text-white  w-100"> Fee in PKR {removeTralingZero(fee.feeInPkr)}</p>
      <div className="d-flex text-white justify-content-between mb-3 align-items-start shadow px-10 py-3 rounded-3 border  flex-column">
        <p className="text-left text-xl font-bold w-100 mb-3 shadow ">EasyPaisa Account</p>
          <p className="text-left text-xl">
          Acc Name  <strong> {accName} </strong>

          </p>
          <p className="text-left text-xl">
          Acc No      <strong> {account}</strong> 

          </p>
        </div>

        <div className="acc-dec" style={{ marginBottom: "10px" }}>
          {isRejected === 1 && (
            <h4 style={{ color: "red", textAlign: "center" }}>
              Payment Rejected! Kindly Try Again with Valid Trx ID
            </h4>
          )}
        </div>

        <div>
        
        </div>
        <form className="needs-validation" onSubmit={handleSubmit} noValidate>
            <div className="mb-2">
              <label className="form-label text-white" htmlFor="bankname">Select Bank</label>
              <select className="form-select rounded-5" name="bankname" required>
                <option value="">Select Bank</option>
                <option value="EasyPaisa">EasyPaisa</option>
                <option value="JazzCash">JazzCash</option>
                <option value="SadaPay">SadaPay</option>
                <option value="NayaPay">NayaPay</option>
                <option value="OtherBank">OtherBank</option>



                <option value="BankAccount">Other Bank</option>
              </select>
              <div className="invalid-feedback">Please select a bank.</div>
            </div>
            {error && <div className="alert bg-danger alert-danger"><p>{error}</p></div>}

            <div className="mb-3">
              <label className="form-label text-white" htmlFor="trx">Enter Trx ID</label>
              <input
                type="text"
                className="form-control rounded-5"
                id="trx"
                required
                placeholder="TRX ID"
                value={trx_id}
                onChange={(e) => setTrxId(e.target.value)}
              />
              <div className="invalid-feedback">Please enter a transaction ID.</div>
            </div>

            <div className="mb-3">
              <label className="form-label text-white" htmlFor="senderName">Enter Sender Name</label>
              <input
                type="text"
                className="form-control rounded-5"
                id="senderName"
                required
                value={sender_name}
                onChange={(e) => setSenderName(e.target.value)}
              />
              <div className="invalid-feedback">Please enter the sender's name.</div>
            </div>

            <div className="mb-3">
              <label className="form-label text-white" htmlFor="senderNumber">Enter Sender Number</label>
              <input
                type="number"
                className="form-control rounded-5"
                id="senderNumber"
                required
                name="phoneNumber"
                value={sender_number}
                onChange={(e) => setSenderPhone(e.target.value)}
              />
              <div className="invalid-feedback">Please enter the sender's number.</div>
            </div>
            <p className="text-center mt-3">
    <a className="text-danger  text-center w-100 cursor" onClick={()=> handleLogout()}>Already Have an account?</a>


    </p>

            <button type="submit" className="btn btn-primary rounded-5 w-100">Submit</button>
          </form>
      </div>


    </>
  );
};

export default Payment;
