import axios from 'axios';
import React, { useEffect, useState } from 'react';
const removeTralingZero = (num) => {
  let numStr = num.toString();
  
  if (numStr.includes('.')) {
    numStr = numStr.replace(/(\.\d*?)0+$/, '$1');
    
    numStr = numStr.replace(/\.$/, ''); 
  }

  // Format with commas for thousands
  return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, "");
}
const SalaryHistory = () => {
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_BASE_URL}/user-salary-requests`, { withCredentials: true })
      .then(response => {
        setWithdrawalRequests(response.data);
        console.log(response.data);
        
        setIsLoading(false);
      })
      .catch(error => {
        setError('Failed to load withdrawal requests.');
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="loader__container">
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
  <div className="loader__circle"></div>
</div>
    );
  }

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <>

    <h1 className="text-white text-2xl  mt-5 ml-3 text-center">Salary  History</h1>
    
    {/* Header Row */}
    <div className="flex justify-between items-center p-3 bg-gray-800 bg-opacity-50 rounded-lg mt-6 text-white">
      <p className="font-semibold">Date</p>
      <p className="font-semibold">Amount</p>
      <p className="font-semibold">Status</p>
    </div>

    <div className="withdraw-history mt-4">
      <div className="new-container">
        <div className="new-content">

          {/* Withdrawal Request Items */}
          <div className="new-list">
            {withdrawalRequests.map((request, index) => {
              const date = new Date(request.date);
              const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
              const formattedDate = date.toLocaleDateString('en-US', options);

              return (
                <div key={`${request.id}-${index}`} className="p-4  backdrop-blur-md rounded-lg shadow-md mt-4">
                  <div className="flex justify-between items-center text-white">
                    <div className="text-sm">{formattedDate}</div>
                    <div className="text-sm">${removeTralingZero(request.amount)}</div>
                    <div className={`text-sm p-1 rounded ${request.approved === 1 ? 'bg-green-500'  : request.approved === 2 ? 'bg-red-500' : 'bg-yellow-400 text-black'}`}>
                      {request.approved === 1 ? 'Approved': request.approved === 2 ? 'Rejected' : 'Pending'}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

        </div>
      </div>
    </div>

    </>
  );
};

export default SalaryHistory;
