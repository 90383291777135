import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../UserContext/UserContext';
import './DailyBonus.css';

const DailyBonus = () => {
  const { Userid, today_team } = useContext(UserContext);
  const [error, setError] = useState('');
  const [clickedButtons, setClickedButtons] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClickedButtons = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/fetchClickedButtons`, {
          withCredentials: true,
        });

        if (response.data.status === 'success') {
          setClickedButtons(response.data.clickedButtons);
        } else {
          console.error('Failed to fetch clicked buttons:', response.data.message);
          setError('Failed to fetch clicked buttons.');
        }
      } catch (error) {
        console.error('Error fetching clicked buttons:', error);
        setError('Failed to fetch clicked buttons.');
      } finally {
        setLoading(false);
      }
    };

    if (Userid) {
      fetchClickedButtons();
    } else {
      setLoading(false); // Ensure loading state is cleared if Userid is not present
    }
  }, [Userid]);

  const handleButtonClick = async (buttonId) => {
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/trackButton`, {
        userId: Userid,
        buttonId,
      });
      setClickedButtons(prevClickedButtons => ({
        ...prevClickedButtons,
        [buttonId]: true,
      }));
    } catch (error) {
      console.error('Error tracking button click:', error);
      setError('You have already collected a bonus today.');
    }
  };

  if (loading) {
    return (
      <div className="loader__container">
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
        <div className="loader__circle"></div>
      </div>
    );
  }

  return (
    <div className="bonus-main w-100 d-flex flex-column justify-content-start   p-4">
      <h5 className='h1 text-white'>Daily Bonus</h5>

      {/* First Bonus - for 3 members */}
      <div className="border rounded p-3 mt-3 bg-gradient-to-r from-purple-500  to-indigo-400  overflow-hidden ">
        <div className="w-100 d-flex justify-content-levenly">
          Add 3 new members & get <span style={{ color: '#68e365', marginLeft: '5px', fontWeight: 'bold' }}>1$</span>
        </div>
        <div className="qt w-100 mt-4"></div>
        <div className="levels-container d-flex justify-content-between align-items-center w-100 corner-lv border mb-0 p-3">
          
          {/* Progress Bar */}
          <div className="progress-bar p-3" style={{ width: `${Math.min((today_team / 3) * 100, 100)}%` }}>
            <div className="circle"></div>
          </div>
        </div>

        {/* Button for collecting bonus */}
        <button
          className={`btn w-full text-white bg-primary rounded-5 animate mt-4 ${clickedButtons[2] || today_team < 3 ? 'disabled' : ''}`}
          onClick={() => handleButtonClick(2)}
          disabled={clickedButtons[2] || today_team < 3}
        >
          {clickedButtons[2] ? 'Bonus Collected' : 'Collect Bonus'}
        </button>
      </div>

      {/* Second Bonus - for 5 members */}
      <div className="border rounded p-3 mt-3 bg-gradient-to-r from-purple-500  to-indigo-400  overflow-hidden ">
        <div className="w-100 d-flex justify-content-levenly">
          Add 5 new members & get <span style={{ color: '#68e365', marginLeft: '5px', fontWeight: 'bold' }}>1$</span>
        </div>
        <div className="qt w-100 mt-4"></div>
        <div className="levels-container d-flex justify-content-between align-items-center w-100 corner-lv border mb-0 p-3">
          
          {/* Progress Bar - Fill only if today_team >= 5 */}
          <div className="progress-bar p-3" style={{ width: `${today_team >= 5 ? Math.min((today_team / 5) * 100, 100) : 0}%` }}>
            <div className="circle"></div>
          </div>
        </div>

        {/* Button for collecting bonus */}
        <button
          className={`btn w-full text-white bg-primary rounded-5 animate mt-4 ${clickedButtons[4] || today_team < 5 ? 'disabled' : ''}`}
          onClick={() => handleButtonClick(4)}
          disabled={clickedButtons[4] || today_team < 5}
        >
          {clickedButtons[4] ? 'Bonus Collected' : 'Collect Bonus'}
        </button>
      </div>

      {/* Third Bonus - for 6 members */}
      <div className="border rounded p-3 mt-3 bg-gradient-to-r from-purple-500  to-indigo-400  overflow-hidden ">
        <div className="w-100 d-flex justify-content-levenly">
          Add 6 new members & get <span style={{ color: '#68e365', marginLeft: '5px', fontWeight: 'bold' }}>1$</span>
        </div>
        <div className="qt w-100 mt-4"></div>
        <div className="levels-container d-flex justify-content-between align-items-center w-100 corner-lv border p-3 mb-0">
          
          {/* Progress Bar - Fill only if today_team >= 6 */}
          <div className="progress-bar p-3" style={{ width: `${today_team >= 6 ? Math.min((today_team / 6) * 100, 100) : 0}%` }}>
            <div className="circle"></div>
          </div>
        </div>

        {/* Button for collecting bonus */}
        <button
          className={`btn w-full text-white bg-primary rounded-5 animate mt-4 ${clickedButtons[5] || today_team < 6 ? 'disabled' : ''}`}
          onClick={() => handleButtonClick(5)}
          disabled={clickedButtons[5] || today_team < 6}
        >
          {clickedButtons[5] ? 'Bonus Collected' : 'Collect Bonus'}
        </button>
      </div>
    </div>
  );
};

export default DailyBonus;
