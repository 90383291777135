import { useState } from 'react';

const Slider = () => {
  // Array of image URLs
  const images = [
    'https://i.ibb.co/1LCLsMc/Colourful-Abstract-Travel-You-Tube-Thumbnail.png',
    'https://i.ibb.co/0GvGYDt/123.png',
    'https://i.ibb.co/K7rDJ3T/122.png',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0); // Track starting point of the swipe

  // Function to go to the previous slide
  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  // Function to go to the next slide
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  // Handle touch start
  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  // Handle touch move and determine swipe direction
  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    if (startX - endX > 50) {
      // Swipe left
      nextSlide();
    } else if (endX - startX > 50) {
      // Swipe right
      prevSlide();
    }
  };

  return (
    <div
      className="slider-wraper relative mx-auto overflow-hidden "
      style={{
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
        borderRadius: '18px',
        maxWidth: '600px',
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {/* Slider Content */}
      <div
        className="flex transition-transform duration-700 ease-in-out"
        style={{
          width: `${images.length * 100}%`, // Ensure that the width covers all images
          transform: `translateX(-${currentIndex * (100 / images.length)}%)`, // Slide to the next image
        }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="flex items-center justify-center"
            style={{
              width: `${100 / images.length}%`,
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              className="object-cover object-center w-full h-full"
            />
          </div>
        ))}
      </div>

      {/* Dots for navigation */}
      <div className="absolute bottom-2 left-0 right-0 flex justify-center space-x-2">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 rounded-full ${
              currentIndex === index ? 'bg-white' : 'bg-gray-400'
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Slider;
