import React, { useContext, useState, useEffect } from 'react';
import './team.css';
import { UserContext } from '../UserContext/UserContext';
import { Link, useParams } from 'react-router-dom';
import TopBar from '../TopBar';
const Team = () => {
  const { Userid ,level } = useContext(UserContext);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading , setLoading] = useState(true);
  

  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const response = await fetch(`${import.meta.env.VITE_API_BASE_URL}/approvedUserNames/${Userid}`);
         
        if (response.ok) {
          const data = await response.json();
          setTeamMembers(data.users);
          setLoading(false);
          
        } else {
          throw new Error('Failed to fetch team members');
        }
      } catch (error) {
        console.error('Error fetching team members:', error);
        setLoading(false);
      }
    };

    if (Userid) {
      fetchTeamMembers();
    }
  }, [Userid]);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  
  return (
    <>
       <div className="relative min-h-screen flex flex-col">
  {/* Blurry Gradient Background */}
  <div className="absolute inset-0 bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900"></div>
  <div className="relative z-10 p-3  backdrop-blur-md rounded-lg shadow-md">
  <TopBar/>

    {/* Team Header */}
    <div className="flex justify-between p-4 items-center">
      <div className="text-xl font-semibold text-white border py-2 border-white rounded-lg px-3">Team ({teamMembers.length})</div>
      <div className="text-xl font-semibold text-white  border py-2 border-white rounded-lg px-3">Level {level ? level : "0"}</div>
    </div>

    {/* Team Container */}
    
      {loading ? (
       <div className="loader__container">
       <div className="loader__circle"></div>
       <div className="loader__circle"></div>
       <div className="loader__circle"></div>
       <div className="loader__circle"></div>
       <div className="loader__circle"></div>
     </div>
      ) : (
        <>
          {teamMembers.length > 0 ? (
            teamMembers.map((member) => (
              <div key={member.id} className="team-card p-4 flex justify-between items-center bg-white bg-opacity-20 backdrop-blur-md rounded-lg shadow-md mt-4">
                <div className="flex items-center justify-between w-full  ">
                  <div className="w-16 h-16 rounded-full overflow-hidden mr-3">
                    <img src="https://reputationprotectiononline.com/wp-content/uploads/2022/04/78-786207_user-avatar-png-user-avatar-icon-png-transparent.png" alt="User Avatar" className="w-full h-full object-cover" />
                    
                    </div>
                    <p className="text-indigo-500 text-lg font-medium">
                      {member.name.length > 21 ? `${member.name.substring(0, 21)}...` : member.name}
                    </p>
                  <div className='flex justify-center align-items-center gap-2'>
                   
                    <p className="text-indigo-300 text-xs mt-1">
                      {formatDate(member.approved_at)}
                    </p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-white text-center mt-6">No members available</div>
          )}
        </>
      )}
    </div>
</div>

    </>
  );
};

export default Team;
