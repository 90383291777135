import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../SideBarSection/Sidebar';
import '../Homepage/home.scss';
import '../Setting/settings.scss';
import { Button } from '@mui/material';

const Settings = () => {
  const [accounts, setAccounts] = useState([]);
  const [fee, setFee] = useState("");
const [usdRate, setUsdRate] = useState(0.0);
const[offer , setOffer] = useState("");
const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/get-accounts`);
        const fee_response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/get-fee`);
        const usd_response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/get-rate`);
        const offer_response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/get-offer`);
        const percentage_response = await axios.get(`${import.meta.env.VITE_API_BASE_URL}/get-percentage`);
        setPercentage(percentage_response.data.initial_percent);
        setOffer(offer_response.data.offer);
        setUsdRate(usd_response.data.rate);
        setFee(fee_response.data.fee);
        setAccounts(response.data.accounts.map(account => ({
          ...account,
          account_id: account.account_id
        })));
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, []);

  const handleUpdateAccounts = async () => {
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/update-accounts`, { accounts });
      alert('Accounts updated successfully!');
    } catch (error) {
      alert('Failed to update accounts!');
    }
  };

  const handleFeeUpdate = async () => {
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/update-fee`, { newFeeValue: fee }); // Use the current fee value
      alert('Fee updated successfully!');
    } catch (error) {
      console.error('Error updating fee:', error);
    }
  };
  const handlePercentageUpdate = async () => {
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/update-percentage`, { newFeeValue: percentage }); // Use the current fee value
      alert('Fee updated successfully!');
    } catch (error) {
      console.error('Error updating fee:', error);
    }
  };
  const handleRateUpdate = async () => {
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/update-usd`, { newFeeValue: usdRate }); // Use the current fee value
      alert('Rate updated successfully!');
    } catch (error) {
      console.error('Error updating fee:', error);
    }
  };

  const handleOfferUpdate = async () => {
    try {
      await axios.post(`${import.meta.env.VITE_API_BASE_URL}/update-offer`, { newOfferValue: offer }); // Use the current fee value
      alert('Offer updated successfully!');
    } catch (error) {
      console.error('Error updating fee:', error);
    }
  };


  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <div className="listContainer">
          <h1>Settings</h1>

          <h3 className="mb-3 text-primary">Joining Fee</h3>
          <div className="d-flex align-items-center mb-3">
            <input
              type="number"
              className="form-control w-25"
              value={fee}
              onChange={(e) => setFee(e.target.value)}
            />
            <button   className="mt-3 btn btn-primary ml-5 btn-sm" onClick={handleFeeUpdate}>
              Update Fee
            </button>
          </div>

          <h3 className="mb-3 text-primary">Givein To New User</h3>
          <div className="d-flex align-items-center mb-3">
            <input
              type="number"
              className="form-control w-25"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
            />
            <button   className="mt-3 btn btn-primary ml-5 btn-sm" onClick={handlePercentageUpdate}>
              Update For New User
            </button>
          </div>

          <h3 className="mb-3 text-primary">USD RATE</h3>
          <div className="d-flex align-items-center mb-3">
            <input
              type="number"
              className="form-control w-25"
              value={usdRate}
              onChange={(e) => setUsdRate(e.target.value)}
            />
            <button  className="mt-3 btn btn-primary ml-5 btn-sm" onClick={handleRateUpdate}>
              Update Rate
            </button>
          </div>

          <h3 className="mb-3 text-primary">Update Offer</h3>
          <div className="d-flex align-items-center mb-3">
            <input
              type="text"
              className="form-control w-25"
              value={offer}
              onChange={(e) => setOffer(e.target.value)}
            />
            <button             className="mt-3 btn btn-primary ml-5 btn-sm"
 onClick={handleOfferUpdate}>
              Update Offer
            </button>
          </div>

          <div className="my-4 bg-dark" style={{ height: '1px', borderRadius: '5px' }}></div>
          
          <h2 className="mb-3 text-primary">EasyPaisa Accounts</h2>
          {accounts.map((account, index) => (
            <div key={index} className="mb-3">
              <div className="d-flex align-items-center mb-2">
                <input
                  type="text"
                  className="form-control w-25"
                  value={account.account_name}
                  onChange={(e) => {
                    const updatedAccounts = [...accounts];
                    updatedAccounts[index].account_name = e.target.value;
                    setAccounts(updatedAccounts);
                  }}
                  placeholder="Account Name"
                />
                <input
                  type="text"
                  className="form-control ml-2 w-25"
                  value={account.account_number}
                  onChange={(e) => {
                    const updatedAccounts = [...accounts];
                    updatedAccounts[index].account_number = e.target.value;
                    setAccounts(updatedAccounts);
                  }}
                  placeholder="Account Number"
                />
                <select
                  className=" ml-2"
                  value={account.status}
                  onChange={(e) => {
                    const updatedAccounts = [...accounts];
                    updatedAccounts[index].status = e.target.value;
                    setAccounts(updatedAccounts);
                  }}
                >
                  <option value="on">On</option>
                  <option value="off">Off</option>
                </select>
              </div>
            </div>
          ))}
          <button
            className="mt-3 btn btn-primary"
            onClick={handleUpdateAccounts}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
