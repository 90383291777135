import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { Button, Table, Form, Spinner } from 'react-bootstrap';

const CryptoUsers = () => {
    const [data, setData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);  
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleRowSelect = (userId) => {
        if (selectedRows.includes(userId)) {
            setSelectedRows(selectedRows.filter(id => id !== userId));
        } else {
            setSelectedRows([...selectedRows, userId]);
        }
    };

    const filteredData = data.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.id.toString().includes(searchTerm.toLowerCase()) ||
        user.trx_id.toString().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/EasypaisaUsers`, {
            params: {
                type: 1
            }
        })
        .then(response => {
            if (response.data && response.data.approvedUsers) {
                setData(response.data.approvedUsers);
            }
        })
        .catch(error => {
            console.error("There was an error fetching the approved users!", error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    const handleApprove = (userId) => {
        return axios.put(`${import.meta.env.VITE_API_BASE_URL}/approveUser/${userId}`, { approved: 1, approved_at: new Date() })
            .then(async (response) => {
                const updatedData = data.filter(user => user.id !== userId);
                setData(updatedData);
              
            })
            .catch(error => {
                console.error("There was an error approving the user!", error);
            });
    };

    const handleReject = (userId) => {
        return axios.put(`${import.meta.env.VITE_API_BASE_URL}/rejectUser/${userId}`)
            .then(response => {
                const updatedData = data.filter(user => user.id !== userId);
                setData(updatedData);
            })
            .catch(error => {
                console.error("There was an error rejecting the user!", error);
            });
    };

    const handleApproveSelected = async () => {
        const promises = selectedRows.map(id => handleApprove(id));
        await Promise.all(promises);
        setSelectedRows([]);
    };

    const handleRejectSelected = async () => {
        const promises = selectedRows.map(id => handleReject(id));
        await Promise.all(promises);
        setSelectedRows([]);
    };

    return (
        <>
            <div className="home">
                <Sidebar />
                <div className="homeContainer">
                    <div className="listContainer">
                        <Form.Control
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearch}
                            className="search-bar mb-3 w-50"
                        />
                        {isLoading ? (
                            <div className="loader-bar">
                                <Spinner animation="border" variant="primary" />
                            </div>
                        ) : (
                            <>
                                <div className="mb-3 m-3">
                                    <Button variant="success"   onClick={handleApproveSelected} >
                                        Approve Selected
                                    </Button>
                                    <Button variant="danger" onClick={handleRejectSelected} className="mr-5">
                                        Reject Selected
                                    </Button>
                                </div>
                                <Table striped bordered hover responsive>
                                    <thead className='bg-primary text-center' >
                                    <tr  >
                                            <th className='bg-primary text-center text-white'>Select</th>
                                            <th className='bg-primary text-center text-white'>TRX ID</th>
                                            <th className='bg-primary text-center text-white'>Approve</th>
                                            <th className='bg-primary text-center text-white'>Reject</th>
                                            <th className='bg-primary text-center text-white'>Name</th>
                                            <th className='bg-primary text-center text-white'>Refer By</th>
                                            <th className='bg-primary text-center text-white'>Email</th>
                                            <th className='bg-primary text-center text-white'>ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData.map((user) => (
                                            <tr key={user.id}>
                                                <td className='m-0 p-0'>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={selectedRows.includes(user.id)}
                                                        onChange={() => handleRowSelect(user.id)}
                                                    />
                                                </td>
                                                <td>{user.trx_id}</td>
                                                <td className='text-center'>
                                                    <Button variant="success" className="btn-sm mr-2 text-center" onClick={() => handleApprove(user.id)}>

Approve                                                    </Button>
                                                </td>
                                                <td className='text-center'>
                                                    <Button variant="danger"  className="btn-sm mr-2 text-center" onClick={() => handleReject(user.id)}>
                                                       Reject
                                                    </Button>
                                                </td>
                                                <td className='text-center'>{user.name}</td>
                                                <td className='text-center'>{user.refer_by}</td>
                                                <td className='text-center'>{user.email}</td>
                                                <td  className='text-center'>{user.id}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CryptoUsers;
