import "./Agreement.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import countryData from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import Logo from './imgs/logo.svg';

countryData.registerLocale(enLocale);

const Agreement = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const countryOptions = countryList().getData(); // Get the country data
  const [fee, setFee] = useState(0);
  const uid = localStorage.getItem("Userid");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${import.meta.env.VITE_API_BASE_URL}/get-fee`)
      .then((res) => {
        setFee(res.data.fee);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, []);

  useEffect(() => {
    if (!uid) {
      navigate("/");
    }
  }, []);

  if (!uid) {
    return null; // this will render nothing if uid is null
  }

  const formattedOptions = countryOptions.map((country) => {
    const alpha3Code = countryData.alpha2ToAlpha3(country.value);
    return {
      value: country.value,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={`https://flagcdn.com/w20/${country.value.toLowerCase()}.png`}
            alt={country.label}
            style={{ marginRight: "10px" }}
          />
          {country.label}
        </div>
      ),
      searchKey: `${country.value} ${alpha3Code} ${country.label.toLowerCase()}`, // Combine searchable terms
    };
  });

  const customFilter = (option, inputValue) => {
    return option.data.searchKey.includes(inputValue.toLowerCase());
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };

  const handleProceed = () => {
    if (selectedCountry && selectedCountry.value === "PK") {
      navigate("/payment");
    } else {
      navigate("/otherscountry");
    }
  };
  const defaultCountry = formattedOptions.find(option => option.label === 'Pakistan'); // Finds the Pakistan option

  return (
    <>

        <div className=" text-center bg-gradient-to-r from-purple-500  to-indigo-400  p-3 vh-100   text-white">
        
        <div className="center mb-3 mt-3 d-flex flex-column align-items-center ">
        <img  src={Logo} width={200} alt="" />

        </div>

      <p className="mt-3 text-gray-200 font-3xl mb-3 font-bold  ">Welcome To Metro Earn ...</p>
      <div className="term-container p-3 border-2 rounded-5 ">
        <div className="">
          <p className="text-2xl text-white  font-bold mt-2">JOINING FEE : $ {fee}</p>
        </div>
        <div className="terms mt-4">
  <ul className="text-white text-start list-disc ml-6">
  <li className="mb-2">joining fee is required to join MetroEarn.</li>
<li className="mb-2">You will receive daily bonuses and rewards 🎁</li>
<li className="mb-2">Weekly salaries will be Pay .</li>
<li className="mb-2"> Level Achievements With Awards 🎉</li>
<li className="mb-2">You can withdraw up to $20 in a day.</li>
<li className="mb-2">Withdrawals can be made from any country.</li>

  </ul>
</div>

     
      </div>
      <div className="mb-3 mt-6" >
  <p className="invalid-feedback">Please select your country.</p>
  <Select
    options={formattedOptions}
    onChange={handleCountryChange}
    value={selectedCountry || defaultCountry}
    className="text-black"
    required
    placeholder="Select Country"
    classNamePrefix="react-select"  // Optional: adds class prefix for more customization
    styles={{
      control: (provided) => ({
        ...provided,
        color: '#1f1f1f',
        borderRadius: '50px', // Change the border radius here
        border: '1px solid #ced4da', // Optional: Customize the border
        padding: '5px',
        boxShadow: 'none', // Remove default shadow
        '&:hover': {
          border: '1px solid #adb5bd',
        },
      }),
      input: (provided) => ({
        ...provided,
        borderRadius: '50px',
        
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#6c757d', // Optional: Customize placeholder color
      }),
    }}
    filterOption={customFilter}
  />
</div>

      <button
        type="button"
        className="w-100 mt-2 bg-primary btn  text-white rounded-5"
        onClick={handleProceed}
      >
        AGREE & PROCEED
      </button>
    </div>
    
    </>

  );
};

export default Agreement;
