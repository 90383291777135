import React from 'react';
import TopBar from '../TopBar';

const NotLevel = () => {
  return (

    <div className='d-flex justify-content- flex-column vh-100 bg-gradient-to-r from-purple-500 p-3 to-indigo-400'>      <TopBar />
      
      <h1 className='text-white h3 font-bold text-center mb-3'>You are not on Level 2</h1>
      <p className='text-white text-center mb-4'>Salary will be available starting from level 2.</p>
      
      {/* Optional: Add a button for navigation */}
     
    </div>
  );
};

export default NotLevel;
