import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./validation.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from "./UserContext/UserContext";
import Logo from './imgs/logo.svg';

const Signup = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { setnewId } = useContext(UserContext);
  const [emptyFields, setEmptyFields] = useState({});



  const handleBlur = (e) => {
    const { name, value } = e.target;
    e.preventDefault()
    setEmptyFields((prevEmptyFields) => {
      const updatedFields = {
        ...prevEmptyFields,
        [name]: !value,
      };
      return updatedFields;
    });
  };

  useEffect(() => {
  }, [emptyFields]);

  const [referrer, setReferrer] = useState("");
  const [err, setError] = useState("");
  const [pherr, setpherr] = useState('')

  const [user, setUser] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    city: "",
    completeAddress: "",
    gender: "",
  });



  const UserHandler = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));


    setUser((prevUser) => ({
      ...prevUser,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Password matching check
    if (user.password !== user.confirmPassword || user.confirmPassword === "") {
      setIsLoading(false);
      toast.error("Password doesn't match/Empty Password");
      return;
    }
    if (user.email === "") {
      setIsLoading(false);
      toast.error("Email cannot be empty");
      return;
    }
  

    // URL formation based on referrer
    const url = referrer
      ? `${import.meta.env.VITE_API_BASE_URL}/register?ref=${referrer}`
      : `${import.meta.env.VITE_API_BASE_URL}/register`;

    try {
      const response = await axios.post(url, user, { withCredentials: true });

      // Handling response
      if (response.data.status === "success") {
        localStorage.setItem("Userid", response.data.userId);
        navigate("/agreement");
      } else {
        setError(response.data.error);
        toast.error(response.data.error);
      }
    } catch (error) {
      setError(error.message);
      toast.error(error.message);
    } finally {
      setIsLoading(false); // Stopping the loading in all cases
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get("ref");
    if (ref) {
      setReferrer(ref);
    }
  }, [location]);


  return (
    <>

        <ToastContainer />



        <div className="  lg:h-auto flex items-center justify-center  bg-gradient-to-r from-purple-500  to-indigo-400   ">

  <div className="   flex flex-col items-center mb-0 lg:mb-0 mt-2">

    <form className="needs-validation  pt-6  mb d-flex flex-column align-items-center " onSubmit={handleSubmit} noValidate>
    <img className='' src={Logo} width={200} alt="" />

   <p className="text-gray-700 text-xl  font-bold mb-3 text-center mt-3">Register as a new user</p>

      <div className="">
        <div className="logo-cont"></div>

        <div className="mb-3">
          <label className={`form-label ${emptyFields.name ? "text-danger" : "text-white"}`} htmlFor="name">
            Name
          </label>
          <input
            type="text"
            className={`form-control rounded-5 ${emptyFields.name ? "is-invalid" : ""}`}
            id="name"
            name="name"
            required
            onInvalid={handleBlur}
            onChange={handleChange}
            onBlur={handleBlur}
            value={user.name}
          />
          <div className="invalid-feedback">Please enter your name.</div>
        </div>

        <div className="mb-3">
          <label className={`form-label text-white ${emptyFields.email ? "text-white" : "text-black"}`} htmlFor="email">
            Email
          </label>
          <input
  type="email"
  className={`form-control rounded-5 ${emptyFields.email ? "is-invalid" : ""}`}
  id="email"
  name="email"
  required
  value={user.email}
  onBlur={handleBlur}
  onChange={UserHandler}
/>

          <div className="invalid-feedback">Please enter a valid email.</div>
        </div>

        <div className="mb-3">
          <label className={`form-label text-white ${emptyFields.gender ? "text-white" : "text-black"}`} htmlFor="gender">
            Gender
          </label>
          <select
            className={`form-select rounded-5 ${emptyFields.gender ? "is-invalid" : ""}`}
            id="gender"
            name="gender"
            value={user.gender}
            onBlur={handleBlur}
            onChange={UserHandler}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="other">Other</option>
          </select>
          <div className="invalid-feedback">Please select your gender.</div>
        </div>

        <div className="mb-3">
          <label className={`form-labe text-white ${emptyFields.city ? "text-white" : "text-black"}`} htmlFor="city">
            City
          </label>
          <input
            type="text"
            className={`form-control rounded-5 ${emptyFields.city ? "is-invalid" : ""}`}
            id="city"
            name="city"
            required
            value={user.city}
            onBlur={handleBlur}
            onChange={UserHandler}
          />
          <div className="invalid-feedback text-white">Please enter your city.</div>
        </div>

        <div className="mb-3">
          <label className={`form-label text-white ${emptyFields.completeAddress ? "text-danger" : "text-black"}`} htmlFor="completeAddress">
            Complete Address
          </label>
          <input
            type="text"
            className={`form-control rounded-5 ${emptyFields.completeAddress ? "is-invalid" : ""}`}
            id="completeAddress"
            name="completeAddress"
            required
            value={user.completeAddress}
            onBlur={handleBlur}
            onChange={UserHandler}
          />
          <div className="invalid-feedback">Please enter your complete address.</div>
        </div>

        <div className="mb-3">
          <label className={`form-label  text-white ${emptyFields.phoneNumber ? "text-white" : "text-black"}`} htmlFor="phoneNumber">
            Phone Number
            {pherr && <p className="text-danger">{pherr}</p>}
          </label>
          <input
            type="number"
            className={`form-control rounded-5  ${emptyFields.phoneNumber ? "is-invalid" : ""}`}
            id="phoneNumber"
            name="phoneNumber"
            required
            value={user.phoneNumber}
            onBlur={handleBlur}
            onChange={UserHandler}
          />
          <div className="invalid-feedback">Please enter your phone number.</div>
        </div>

        <div className="mb-3">
          <label className={`form-labelrounded-5 text-white ${emptyFields.password ? "text-danger" : "text-black"}`} htmlFor="password">
            Password
          </label>
          <input
            type="password"
            className={`form-control rounded-5 ${emptyFields.password ? "is-invalid" : ""}`}
            id="password"
            name="password"
            required
            onBlur={handleBlur}
            onInvalid={handleBlur}
            value={user.password}
            onChange={UserHandler}
          />
          <div className="invalid-feedback">Please enter your password.</div>
        </div>
        <div className="mb-3">
          
          <label className={`form-label text-white ${emptyFields.confirmPassword ? "text-danger" : "text-black"}`} htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            type="password"
            className={`form-control rounded-5 ${emptyFields.confirmPassword ? "is-invalid" : ""}`}
            id="confirmPassword"
            name="confirmPassword"
            onBlur={handleBlur}
            onInvalid={handleBlur}
            value={user.confirmPassword}
            onChange={UserHandler}
            required
          />
          <div className="invalid-feedback">Please confirm your password.</div>
        </div>
          <p className="mb-2 text-center"><a href="/" className="text-white underline">Already have an account?</a></p>
          <button type="submit" className="bg-blue-600 w-full text-white px-4 py-2 mb-4 rounded-5 rounded hover:bg-blue-700 disabled:opacity-50" disabled={isLoading}>
          {isLoading ? "Loading..." : "Register"}
        </button>
      </div>
    </form>
  </div>
</div>

    </>
  );
};

export default Signup;