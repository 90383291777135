import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Ensure you have react-bootstrap installed
import './BonusCard.css';
import axios from 'axios';

const BonusCard = () => {
  const [showModal, setShowModal] = useState(false);
  const [isCollecting, setIsCollecting] = useState(false);
  const [alreadyCollected, setAlreadyCollected] = useState(false); // New state for already collected bonus

  const handleCloseModal = () => {
    setShowModal(false);
    setAlreadyCollected(false); // Reset the already collected state when modal is closed
  };
  const handleCollectBonus = async () => {
    setIsCollecting(true);
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_API_BASE_URL}/collectBonus`,
        {}, 
        { withCredentials: true }
      );
  
      if (response.data.status === 'success') {
        setShowModal(true);
      } else if (response.status === 403 && response.data.status === 0) {
        setAlreadyCollected(true);
        setShowModal(true);
      } else {
        console.error('Failed to collect bonus:', response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error('Bonus already collected or not eligible:', error.response.data.message);
        setAlreadyCollected(true);
        setShowModal(true);
      } else {
        console.error('Failed to collect bonus', error);
      }
    } finally {
      setIsCollecting(false);
    }
  };
  

  return (
    <div className="vh-100">
    <div className="bonus-card ">
      <div className="bonus-content">
        <h3 className="text-warning text-bold">Level Up Bonus</h3>
        <p className="mb-3">Click the button to collect your Level Achievement Bonus 🏆</p>
        <Button
          className={`collect-button ${isCollecting ? 'loading' : ''}`}
          onClick={handleCollectBonus}
          disabled={isCollecting}
        >
          {isCollecting ? 'Collecting...' : 'Collect Bonus'}
        </Button>
      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered className="modern-modal bg-transparent">
        <div className="modal-body-custom bg-transparent">
          <div className="graphic-container text-center bg-transparent">
            {alreadyCollected ? (
              <>
                <div className="bonus-graphic">❌</div> {/* Cross sign if already collected */}
                <h4>Not Eligible!</h4>
              </>
            ) : (
              <>
                <div className="bonus-graphic">🎉</div> {/* Celebration graphic if successfully collected */}
                <h4>Bonus Collected!</h4>
              </>
            )}
          </div>
          <p className="modal-message">
            {alreadyCollected
              ? "You can collect your bonus on Level Up!"
              : "You've successfully collected your bonus. Keep it up!"}
          </p>
          <button  className="close-modal-button text-white bg-success rounded-5 w-100" onClick={handleCloseModal}>
            {alreadyCollected ? "Got it!" : "Awesome!"}
          </button>
          <div className="particles">
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
            <div className="particle"></div>
          </div>
        </div>
      </Modal>
    </div>

    </div>

  );
};

export default BonusCard;
