import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { Table, Button, FormControl } from 'react-bootstrap'; // Import necessary components from Bootstrap

const RejectWithdraw = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleSort = (field) => {
        setSortField(field);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    }

    const filteredData = data.filter(user =>
        user.id.toString().includes(searchTerm.toLowerCase()) ||
        user.account_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.account_number.toString().includes(searchTerm.toLowerCase())
    );

    const sortedData = [...filteredData].sort((a, b) => {
        const aValue = a[sortField];
        const bValue = b[sortField];

        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/withdrawalRequestsRejected`)
            .then(response => {
                if (Array.isArray(response.data.data)) {
                    setData(response.data.data);
                } else {
                    console.error('Data is not an array:', response.data.data);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the withdrawal requests!", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const approveRequest = (userId, requestId, amount) => {
        if (!userId || !requestId || !amount) {
            console.error('User ID, request ID, and amount are required');
            return;
        }

        axios.post(`${import.meta.env.VITE_API_BASE_URL}/approve-withdrawal`, { userId, requestId, amount })
            .then(response => {
                const updatedData = data.filter(item => item.id !== requestId);
                setData(updatedData);
            })
            .catch(error => {
                console.error('There was an error approving the request!', error);
            });
    };

    const formatDateAndTime = (dateString) => {
        const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString('en-GB', options).replace(',', '');
    }

    const buttonStyle = {
        padding: '5px 10px',
        fontSize: '12px',
        borderRadius: '5px',
        margin: '0 5px',
        cursor: 'pointer',
    };

    const approveButtonStyle = {
        ...buttonStyle,
        backgroundColor: 'green',
        color: 'white',
    };

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <div className="listContainer">
                    <FormControl
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearch}
                        className="mb-3"
                    />
                    {isLoading ? (
                        <div className="loader-bar"></div>
                    ) : (
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th  className='bg-primary text-light' onClick={() => handleSort('amount')}>Amount</th>
                                    <th  className='bg-primary text-light' onClick={() => handleSort('account_name')}>Account Name</th>
                                    <th  className='bg-primary text-light' onClick={() => handleSort('account_number')}>Account Number</th>
                                    <th  className='bg-primary text-light' onClick={() => handleSort('bank_name')}>Bank Name</th>
                                    <th  className='bg-primary text-light'>Action</th>
                                    <th  className='bg-primary text-light' onClick={() => handleSort('request_date')}>Request Date</th>
                                    <th  className='bg-primary text-light' onClick={() => handleSort('approved_time')}>Reject At</th>
                                    <th  className='bg-primary text-light' onClick={() => handleSort('user_id')}>User ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map(row => (
                                    <tr key={row.id}>
                                        <td>{row.amount}</td>
                                        <td>{row.account_name}</td>
                                        <td>{row.account_number}</td>
                                        <td>{row.bank_name}</td>
                                        <td>
                                            <Button style={approveButtonStyle} onClick={() => approveRequest(row.user_id, row.id, row.amount)}>Approve</Button>
                                        </td>
                                        <td>{formatDateAndTime(row.request_date)}</td>
                                        <td>{formatDateAndTime(row.approved_time)}</td>
                                        <td>{row.user_id}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RejectWithdraw;
