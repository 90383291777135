import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FaCopy, FaHome } from 'react-icons/fa';
import tick from '../imgs/tick.png';

import axios from 'axios';
import './CryptoPayment.css';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext/UserContext';

const CryptoPayment = () => {
  const [trx_id, setTrxId] = useState("");
  const [address] = useState("TGuHv9nP1Bsp4aABt3TpcaubrVhv5ega82");
  const [fee, setFee] = useState(0);
  const { logout } = useContext(UserContext);
const navigate  = useNavigate();
  useEffect(() => {
    axios.get(`${import.meta.env.VITE_API_BASE_URL}/get-fee`)
      .then(response => {
        setFee(response.data.fee);
      })
      .catch(error => {
        console.error('Error fetching fee:', error);
      });
  })

  const copyAddress = () => {
    navigator.clipboard.writeText(address)
      .then(() => {
        alert('Address copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy address: ', err);
      });
  };

  const handleSubmit = async () => {
    const id = localStorage.getItem('Userid');
    if(
      trx_id === address || trx_id === ""
    )
    {
      alert("Invalid Transaction ID");
      return
    }
    
    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/payment-crypto`, {
        trx_id,
        id
      });
      if (response.data.status === 'success') {
        navigate('/waiting');
      } else {
        alert('Error submitting transaction ID: ' + response.data.error);
      }
    } catch (error) {
      alert('Failed to submit transaction ID');
    }
  };

 

  return (
<>

<div className="d-flex justify-content-center align-items-center bg-gradient-to-r from-purple-500  to-indigo-400  text-white  p-2">
  <div>
    <div className="text-center">
      <h2 className='text-2xl font-bold'>Crypto Payment</h2>
      <p className="mt-3">In your selected country, we only accept payments via cryptocurrency.</p>
      <p className="mt-3">
        <b>Joining Fee: {fee} USDT (TRC 20)</b>
      </p>
      <p>Please make the payment to the address below and submit your transaction ID.</p>

      <p className="mt-3 text-gray-700 mb-2 font-weight-bold">Scan the QR code or copy the address to proceed with payment</p>
      <div className="d-flex justify-content-center">
        <img src="https://i.ibb.co/d5R6Vn8/Screenshot-from-2024-09-19-17-15-33.png" alt="QR Code" style={{ width: 200, height: 200 }} />
      </div>

      <p>{address}</p>
      <button onClick={copyAddress} className="btn btn-primary w-100 mt-3 mb-1 mt-0 d-flex justify-content-between align-items-center">
        Copy Address <FaCopy />
      </button>
    </div>

    <p
      style={{
        color: '#2f313f',
        fontSize: '14px',
        textAlign: 'left',
        marginLeft: '10px',
        marginBottom: '4px',
        marginTop: '10px',
      }}
    >
      Once the transaction is successful, enter the transaction ID below.
    </p>
    <input
      type="text"
      className="form-control"
      onChange={(e) => setTrxId(e.target.value)}
      placeholder="Transaction ID"
      value={trx_id}
    />
    <button className="mt-3 w-100 btn-success btn mt-3 mb-3" onClick={handleSubmit}>
      Submit
    </button>

   
  </div>
</div>

</>


  );
};

export default CryptoPayment;