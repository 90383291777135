import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBarSection/Sidebar';
import axios from 'axios';

const AdminAbout = () => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true); 
  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const fetchImage = () => {
    axios.get(`${import.meta.env.VITE_API_BASE_URL}/getImage`)
      .then((response) => {
        const imageUrl = `${import.meta.env.VITE_API_BASE_URL}/${response.data.file_path}`;
        setImageUrl(imageUrl);
        setIsImageLoading(true); 
      })
      .catch((error) => {
        console.error("There was an error fetching the image!", error);
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', file);

    axios.post(`${import.meta.env.VITE_API_BASE_URL}/upload`, formData)
      .then((response) => {
        fetchImage();  // Fetch image from database after it is uploaded
      })
      .catch((error) => {
        console.error("There was an error uploading the file!", error);
      });
  };

  useEffect(() => {
    // Fetch the image when the component is mounted
    fetchImage();
  }, []);

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <h1>        About Image
        </h1>
        <div className="listContainer">
      

          <form onSubmit={onFormSubmit}>
            <input type="file" onChange={onFileChange} />
            <button type="submit">Upload</button>
          </form>
          
          {isImageLoading && <div className="image-loader-bar"></div>}
                {imageUrl && <img src={imageUrl} alt="Uploaded" width="300" onLoad={() => setIsImageLoading(false)} />}
                          
          </div>
          
      </div>
    </div>
  );
};

export default AdminAbout;
