import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserProfileUpdate.css';
import { UserContext } from './UserContext/UserContext';
import TopBar from './TopBar';

const UserProfileUpdate = () => {
  const { userData, theme } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const fileInputRef = useRef(null);

  const fallbackProfilePicture = 'https://img.freepik.com/free-photo/view-3d-woman_23-2150709984.jpg?t=st=1723488019~exp=1723491619~hmac=3247acb5ea34e8f4cb660b0e1926b8b1f35bfa06a46d6a6ee4a5756dbce791dd&w=826';
const  female='https://img.freepik.com/premium-vector/man-character_665280-46970.jpg'
  useEffect(() => {
    if (userData && userData.profile_picture) {
      const profilePictureUrl = `${import.meta.env.VITE_API_BASE_URL}/${userData.profile_picture}`;
      setProfilePicture(profilePictureUrl);
    } else {
      if (userData?.gender === 'Female') {
        setProfilePicture(fallbackProfilePicture);
      } else {
        setProfilePicture(female);
      }
    }
    setLoading(false);
  }, [userData]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      navigate('/');
    } else {
      setName(userData.name);
      setEmail(userData.email);
      setLoading(false);
    }
  }, [userData, navigate]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleProfilePictureClick = () => {
    fileInputRef.current.click();
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    setUpdating(true);

    try {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);

      if (profilePicture && profilePicture instanceof File) {
        formData.append('profilePicture', profilePicture);
      }
      formData.append('currentPassword', currentPassword);
      formData.append('newPassword', newPassword);

      const response = await axios.put(`${import.meta.env.VITE_API_BASE_URL}/updateProfile`, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setUpdateSuccess(true);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setPasswordError('');

      if (response.data.profile_picture) {
        const updatedProfilePictureUrl = `${import.meta.env.VITE_API_BASE_URL}/${response.data.profile_picture}`;
        setProfilePicture(updatedProfilePictureUrl);
        setPreviewImage(null);
      }

    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data && error.response.data.error) {
        setPasswordError(error.response.data.error);
      }
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="loader__container">
      <div className="loader__circle"></div>
      <div className="loader__circle"></div>
      <div className="loader__circle"></div>
      <div className="loader__circle"></div>
      <div className="loader__circle"></div>
    </div>
    );
  }

  return (
    <>
    <div className="relative">
  {/* Blurry Background */}
  <div className="absolute inset-0 bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900 "></div>
  
  {/* Content Section */}
  <div className="relative z-10 p-3">
  <TopBar/>

    
      <div className="profile-image-container" onClick={handleProfilePictureClick}>
        <div
          className="profile-image-background"
    
        ></div>
        <img
          src={previewImage || profilePicture}
          alt="Profile"
          className="profile-image"
        />
      </div>

      <form onSubmit={handleUpdate} encType="multipart/form-data" className="profile-form w-100  vh-100 p">
        {passwordError && <p className="text-danger">{passwordError}</p>}
        <input
          type="file"
          className="form-control mb-2"
          id="profilePicture"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <div className="form-group">
          <label htmlFor="name" className="form-label text-white mb-2">Name</label>
          <input
            type="text"
            className="form-control bg-transparent text-white-50 mb-2"
            id="name"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label mb-1 text-white mb-2 mt-2">Email</label>
          <input
            type="email"
            className="form-control bg-transparent mb-2 text-white-50 mb-2"
            id="email"
            placeholder="Email"
            value={email}
            disabled
          />
        </div>
        <div className="form-group">
          <label htmlFor="currentPassword" className="form-label mb-1 text-white mb-2 mt-2">Current Password</label>
          <input
            type="password"
            className="form-control bg-transparent mb-2 text-white-50 mb-2"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword" className="form-label mb-1 text-white mb-2 mt-2">New Password</label>
          <input
            type="password"
            className="form-control bg-transparent mb-2 text-white-50 mb-2"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword" className="form-label mb-1 text-white mb-2">Confirm Password</label>
          <input
            type="password"
            className="form-control bg-transparent mb-3 text-white-50 mb-2"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="btn w-100 btn-sm btn-success text-white"
          style={{ letterSpacing: '1px', borderRadius: '50px', fontFamily: 'Poppins', fontWeight: '400', fontSize: '18px' }}
          disabled={updating}
        >
          {updating ? (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span className="sr-only">Loading...</span>
            </>
          ) : (
            "UPDATE"
          )}
        </button>
      </form>
    </div>
  </div>

    </>
  );
};

export default UserProfileUpdate;
