import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { UserContext } from '../UserContext/UserContext';

const AdminLogin = () => {
    const { setAdminAuthenticated } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [loginUserName, setLoginUserName] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const navigateTo = useNavigate();
    const [loginStatus, setLoginStatus] = useState('');
    const [statusHolder, setStatusHolder] = useState('message');
    function decodeJwt(token) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map((char) => {
                        return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            return JSON.parse(jsonPayload);
        } catch (error) {
            console.error('Error decoding JWT:', error);
            return null;
        }
    }
    
    const loginUser = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (!loginUserName || !loginPassword) {
            setLoginStatus(`Please enter username and password!`);
            setIsLoading(false);
            return;
        }

        Axios.post(`${import.meta.env.VITE_API_BASE_URL}/admin-login`, {
            LoginUserName: loginUserName,
            LoginPassword: loginPassword
        }).then((response) => {
            if (response.data.message) {
                setLoginStatus(response.data.message);
            } else {
                const token = response.data.token; // Assuming your API returns a token
                const decodedUser = decodeJwt(token); // Decode the JWT token
                console.log('Decoded JWT:', decodedUser); // Log the decoded JWT token
                setAdminAuthenticated(true); 
                localStorage.setItem('adminToken', token); // Store the actual token instead of a boolean value
                navigateTo('/adminpanel');
            }
        }).catch(error => {
            console.error(error);
            setLoginStatus('An error occurred while logging in.');
        }).finally(() => {
            setLoginUserName('')
            setLoginPassword('')
            setIsLoading(false);
        });
        
    }


    useEffect(() => {
        if (loginStatus !== '') {
            setStatusHolder('showMessage');
            setTimeout(() => {
                setStatusHolder('message');
            }, 2000);
        }
    }, [loginStatus]);

    return (
        <>
    <div className="container d-flex justify-content-center align-items-center vh-100  bg-white">
        <div className="card p-4">
          <h2 className="text-center mb-4">Admin Login</h2>
          {loginStatus && <p className="text-danger mb-4">{loginStatus}</p>}
          <form onSubmit={loginUser}>
            <div className="mb-3">
              <label htmlFor="email_field" className="form-label">Email</label>
              <input
                type="text"
                className="form-control"
                id="email_field"
                value={loginUserName}
                onChange={(e) => setLoginUserName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password_field" className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                id="password_field"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
              {isLoading ? (
                <div className="spinner-border spinner-border-sm" role="status"></div>
              ) : (
                'Sign In'
              )}
            </button>
          </form>
        </div>
        </div>
        </>
    );
}

export default AdminLogin;
