import React, { useState } from 'react';
import Sidebar from '../SideBarSection/Sidebar';
import axios from 'axios';
import '../AccountSetting/AccountStyles.css'
export const AccountSetting = () => {
  const [username, setUsername] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState(''); // To display the message to the user
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${import.meta.env.VITE_API_BASE_URL}/changePassword`, {
        username,
        oldPassword,
        newPassword
      });

      setMessage(response.data.message); // Assuming the backend returns a message in the response
    } catch (error) {
      setMessage('Failed to update password');
    }
    finally {
      setIsLoading(false); 
      setMessage('Password updated successfully');
    }
  };

  return (
    <>
       <div className="home">

        <Sidebar />
        {isLoading ? (
                        <div className="loader-bar"></div>
                    ) : (

        <div className="homeContainer homeContainer-acc">

          <div className="form-container">

            <form onSubmit={handleSubmit}>
                        {message && <p style={{ color: 'red',fontSize: '20px' }}>{message}</p>}

              <div className="input-group">
                <label className='label-acc' >Username</label>
                <input 
                  type="text" 
                  value={username} 
                  onChange={(e) => setUsername(e.target.value)} 
                  required 
                  className='acc-input'

                />
              </div>
              <div className="input-group">
                <label className='label-acc'>Old Password</label>
                <input 
                  type="password" 
                  value={oldPassword} 
                  onChange={(e) => setOldPassword(e.target.value)} 
                  required 
                  className='acc-input'

                />
              </div>
              <div className="input-group">
                <label className='label-acc'>New Password</label>
                <input 
                  type="password" 
                  value={newPassword} 
                  onChange={(e) => setNewPassword(e.target.value)} 
                  required 
                  className='acc-input'
                />
              </div>
              <button className='acc-btn' type="submit" disabled={isLoading}>
                {isLoading ? <span>Loading...</span> : <span>Change Password</span>}
              </button>
            </form>
          </div>
        </div>
                    )}
      </div>
                  
    </>
  );
};
