import React, { useContext } from 'react';
import WeakBonus from './WeakBonus';
import TopBar from '../TopBar';
import './WithdrawalPage.css'; 
import SalaryHistory from './SalaryHistory';
import { UserContext } from '../UserContext/UserContext';
import NotLevel from './NotLevel';

const ShowSalary = () => {
    const { level } = useContext(UserContext);

    return (
        <>
            {/* Show NotLevel if user's level is less than 2 */}
            {level < 2 ? (
                <NotLevel />
            ) : (
                <div className="p-3 bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900">
                    <TopBar />
                    <div className="bg-indigo-900 bg-opacity-10 rounded-lg">
                        <WeakBonus />
                        {/* Wrap SalaryHistory in a scrollable div */}
                        <div className="scrollable-div bg-gradient-to-r from-indigo-900 via-purple-900 to-pink-900 vh-100">
                            <SalaryHistory />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ShowSalary;
