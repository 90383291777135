import { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// Import Components
import Agreement from './Agreement';
import { Login } from './Login';
import Payment from './Payment';
import Signup from './Signup';
import Waiting from './Waiting';
import UserProfileUpdate from './UserProfileUpdate';
import DailyTasks from './DailyTasks';
import AdminLogin from './Dashboard/AdminLogin';
import HomePage from './Dashboard/Homepage/HomePage';
import ApprovedUsers from './Dashboard/Users/ApprovedUsers';
import EasyPaisa from './Dashboard/EasyPaisa/EasyPaisa';
import RejectedUsers from './Dashboard/RejectedUser/RejectedUsers';
import TodayApproved from './Dashboard/TodayApproved/TodayApproved';
import WithdrwaReques from './Dashboard/WithdrawRequest/WithdrwaReques';
import ApprovedWithdraw from './Dashboard/ApprovedWithdraw/ApprovedWithdraw';
import Product from './Dashboard/Products/Products';
import Settings from './Dashboard/Setting/Setting';
import Widgets from './Dashboard/Widgets/Widgets';
import PendingUsers from './Dashboard/PendingUsers/PendingUsers';
import AdminAbout from './Dashboard/About/AdminAbout';
import { AccountSetting } from './Dashboard/AccountSetting/AccountSetting';
import RejectWithdraw from './Dashboard/RejectWithdraw/RejectWithdraw';
import WalletPage from './new/WalletPage';
import Team from './new/Team';
import Level from './Dashboard/Levels/Levels';
import Commission from './Dashboard/Commission/Commission';
import WithdrawLimits from './Dashboard/WithdrawLimits/WithdrawLimits';
import WithdrwaHistory from './new/WithdrwaHistory';
import { UserContext } from './UserContext/UserContext';
import Bonus from './new/Bonus';
import TeamDetails from './new/TeamDetails';
import ShowWithdrwa from './new/ShowWithdrwa';
import CryptoPayment from './new/CryptoPayment';
import CryptoUsers from './Dashboard/EasyPaisa/CryptoUsers';
import WeakBonus from './new/WeakBonus';
import SalaryRequest from './Dashboard/WithdrawRequest/SalaryRequest';
import ShowSalary from './new/ShowSalary';
function App() {
  const [isLoading, setIsLoading] = useState(true);
  const { isRejected, Userid, setAdminAuthenticated, currBalance, approved, adminAuthenticated, isAuthenticated, fetchUserData } = useContext(UserContext);

  const decodeJwt = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64).split('').map((char) => {
          return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
        }).join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  };

  useEffect(() => {
    const adminToken = localStorage.getItem('adminToken');

    if (adminToken) {
      const decodedToken = decodeJwt(adminToken);

      if (decodedToken && decodedToken.isAdmin) {
        setAdminAuthenticated(true);
      } else {
        setAdminAuthenticated(false);
      }
    } else {
      setAdminAuthenticated(false);
    }

    fetchUserData().finally(() => setIsLoading(false));
  }, [ setAdminAuthenticated]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loader-bar"></div>
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/adminlogin' element={adminAuthenticated ? <Navigate to="/adminpanel" /> : <AdminLogin />} />
        <Route path='/users' element={adminAuthenticated ? <ApprovedUsers /> : <Navigate to="/adminlogin" />} />
        <Route path='/easypaisa' element={adminAuthenticated ? <EasyPaisa /> : <Navigate to="/adminlogin" />} />
        <Route path='/rejecteduser' element={adminAuthenticated ? <RejectedUsers /> : <Navigate to="/adminlogin" />} />
        <Route path='/todayApproved' element={adminAuthenticated ? <TodayApproved /> : <Navigate to="/adminlogin" />} />
        <Route path='/withdrwa' element={adminAuthenticated ? <WithdrwaReques /> : <Navigate to="/adminlogin" />} />
        <Route path='/salaryRequest' element={adminAuthenticated ? <SalaryRequest /> : <Navigate to="/adminlogin" />} />

        <Route path='/cryptoUser' element={ adminAuthenticated ? <CryptoUsers/> : <AdminLogin/>} />  
        <Route path='/ApprovedWithdrwa' element={adminAuthenticated ? <ApprovedWithdraw /> : <Navigate to="/adminlogin" />} />
        <Route path='/products' element={adminAuthenticated ? <Product /> : <Navigate to="/adminlogin" />} />
        <Route path='/accounts' element={adminAuthenticated ? <Settings /> : <Navigate to="/adminlogin" />} />
        <Route path='/adminpanel' element={adminAuthenticated ? <Widgets /> : <Navigate to="/adminlogin" />} />
        <Route path='/pending' element={adminAuthenticated ? <PendingUsers /> : <Navigate to="/adminlogin" />} />
        <Route path='/adminabout' element={adminAuthenticated ? <AdminAbout /> : <Navigate to="/adminlogin" />} />
        <Route path='/accountsetting' element={adminAuthenticated ? <AccountSetting /> : <Navigate to="/adminlogin" />} />
        <Route path='/rejectwithdrwa' element={adminAuthenticated ? <RejectWithdraw /> : <Navigate to="/adminlogin" />} />
        <Route path='/levels' element={adminAuthenticated ? <Level /> : <Navigate to="/adminlogin" />} />
        <Route path='/commission' element={adminAuthenticated ? <Commission /> : <Navigate to="/adminlogin" />} />
        <Route path='/withdrwa_limits' element={adminAuthenticated ? <WithdrawLimits /> : <Navigate to="/adminlogin" />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/agreement' element={<Agreement />} />
        <Route path='/Payment' element={<Payment Userid={Userid} isRejected={isRejected} />} />        <Route path='/waiting' element={ <Waiting />  } />
        <Route path='/' element={<Login />} />
        <Route path='/admin' element={<HomePage />} />
        <Route path='/setting' element={approved === 1 && isAuthenticated ? <UserProfileUpdate /> : <Login />} />
        <Route path='/tasks' element={approved === 1 && isAuthenticated ? <DailyTasks Userid={Userid} currBalance={currBalance} /> : <Login />} />
        <Route path='/walletpage' element={approved === 1 && isAuthenticated ? <WalletPage /> : <Login />} />
        <Route path='/team' element={approved === 1 && isAuthenticated ? <Team /> : <Login />} />
        <Route path='/withdrawhistory' element={approved === 1 && isAuthenticated ? <WithdrwaHistory /> : <Login />} />
        <Route path='/getwithdraw' element={approved === 1 && isAuthenticated ? <ShowWithdrwa /> : <Login />} />
        <Route path='/bonus' element={approved === 1 && isAuthenticated ? <Bonus /> : <Login />} />
        <Route path='/salary' element={approved === 1 && isAuthenticated ? <ShowSalary /> : <Login />} />

        <Route path='/otherscountry' element={<CryptoPayment  />} />
        <Route path='/teamdetails' element={approved === 1 && isAuthenticated ? <TeamDetails /> : <Login />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
