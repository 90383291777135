import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { Button, Table, Spinner } from 'react-bootstrap';
import '../Users/users.css';

const PendingUsers = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });

    useEffect(() => {
        fetchPendingUsers();
    }, []);

    useEffect(() => {
        const filtered = data.filter(user =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.id.toString().includes(searchTerm.toLowerCase())
        );
        setFilteredData(sortData(filtered));
    }, [searchTerm, data, sortConfig]);

    const fetchPendingUsers = () => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/pending-users`)
            .then(response => {
                if (response.data.success) {
                    setData(response.data.pendingUsers);
                    setFilteredData(response.data.pendingUsers);
                } else {
                    console.error(response.data.message);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the pending users!", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleDelete = (user) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");
        if (confirmDelete) {
            axios.delete(`${import.meta.env.VITE_API_BASE_URL}/delete-user/${user.id}`)
                .then(response => {
                    if (response.data.success) {
                        const updatedData = data.filter(u => u.id !== user.id);
                        setData(updatedData);
                        setFilteredData(updatedData);
                    } else {
                        alert('Error deleting user: ' + response.data.message);
                    }
                })
                .catch(error => {
                    alert('Error deleting user: ' + error);
                });
        }
    };

    const handleDelete7DaysOldUsers = () => {
        const confirmDelete = window.confirm("Are you sure you want to delete users who are 7 days old and have not been approved or paid?");
        if (confirmDelete) {
            axios.delete(`${import.meta.env.VITE_API_BASE_URL}/delete-7-days-old-users`)
                .then(response => {
                    if (response.data.success) {
                        alert('Users deleted successfully');
                        fetchPendingUsers();
                    } else {
                        alert('Error deleting users');
                    }
                })
                .catch(error => {
                    alert('Error deleting users: ' + error);
                });
        }
    };

    const handleSort = (key) => {
        const direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        setSortConfig({ key, direction });
    };

    const sortData = (array) => {
        return [...array].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
    };

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <Button variant="contained" onClick={handleDelete7DaysOldUsers}>Delete 7 Days Record</Button>
                <div className="listContainer">
                    <input
                        className='search-bar'
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearch}
                    />
                    {isLoading ? (
                     <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                     <Spinner animation="border" role="status">
                         <span className="visually-hidden">Loading...</span>
                     </Spinner>
                 </div>
                    ) : (
                        <Table striped bordered hover>
                            <thead className="table-header">
                                <tr>
                                    <th className='bg-primary text-light' onClick={() => handleSort('id')}>ID</th>
                                    <th className='bg-primary text-light' onClick={() => handleSort('name')}>Name</th>
                                    <th className='bg-primary text-light' onClick={() => handleSort('email')}>Email</th>
                                    <th className='bg-primary text-light' onClick={() => handleSort('phoneNumber')}>Phone Number</th>
                                    <th className='bg-primary text-light' onClick={() => handleSort('cnic')}>CNIC</th>
                                    <th className='bg-primary text-light' onClick={() => handleSort('completeAddress')}>Address</th>
                                    <th className='bg-primary text-light'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.length === 0 ? (
                                    <tr>
                                        <td colSpan="7" className="text-center">No data available</td>
                                    </tr>
                                ) : (
                                    filteredData.map(user => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phoneNumber}</td>
                                            <td>{user.cnic}</td>
                                            <td>{user.completeAddress}</td>
                                            <td>
                                                <Button variant="danger" onClick={() => handleDelete(user)}>Delete</Button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PendingUsers;
