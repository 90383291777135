import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { Button, Table, Spinner } from 'react-bootstrap';

const TodayApproved = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: 'ascending' });

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/todayApproved`)
            .then(response => {
                if (response.data && response.data.approvedUsers) {
                    setData(response.data.approvedUsers);
                    setFilteredData(response.data.approvedUsers);
                }
            })
            .catch(error => {
                console.error("There was an error fetching the approved users!", error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        const filtered = data.filter(user =>
            user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.id.toString().includes(searchTerm.toLowerCase()) ||
            user.trx_id.toString().includes(searchTerm.toLowerCase())
        );
        setFilteredData(sortData(filtered));
    }, [searchTerm, data, sortConfig]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleReject = (userId) => {
        axios.put(`${import.meta.env.VITE_API_BASE_URL}/rejectUser/${userId}`)
            .then(() => {
                const updatedData = data.filter(user => user.id !== userId);
                setData(updatedData);
                setFilteredData(updatedData);
            })
            .catch(error => {
                console.error("There was an error rejecting the user!", error);
            });
    };

    const handleSort = (key) => {
        const direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
        setSortConfig({ key, direction });
    };

    const sortData = (array) => {
        const sortedArray = [...array].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        return sortedArray;
    };

    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <div className="listContainer">
                    <input
                        className='search-bar'
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearch}
                    />
                    {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <Table striped bordered hover>
                            <thead >
                                <tr>
                                    <th className='table-header bg-primary text-light' onClick={() => handleSort('id')}>ID</th>
                                    <th className='table-header bg-primary text-light' onClick={() => handleSort('name')}>Name</th>
                                    <th className='table-header bg-primary text-light' onClick={() => handleSort('email')}>Email</th>
                                    <th className='table-header bg-primary text-light' onClick={() => handleSort('trx_id')}>TRX ID</th>
                                    <th className='table-header bg-primary text-light' onClick={() => handleSort('refer_by')}>ReferrBy</th>
                                    <th className='table-header bg-primary text-light' onClick={() => handleSort('balance')}>Balance</th>
                                    <th className='table-header bg-primary text-light' onClick={() => handleSort('completeAddress')}>Address</th>
                                    <th className='table-header bg-primary text-light'>Reject</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.length === 0 ? (
                                    <tr>
                                        <td colSpan="9" className="text-center">No data available</td>
                                    </tr>
                                ) : (
                                    filteredData.map(user => (
                                        <tr key={user.id}>
                                            <td>{user.id}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.trx_id}</td>
                                            <td>{user.refer_by}</td>
                                            <td>{user.balance}</td>
                                            <td>{user.completeAddress}</td>
                                            <td>
                                                <Button variant="danger" onClick={() => handleReject(user.id)}>
                                                    Reject
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TodayApproved;
