import "./sidebar.scss";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../UserContext/UserContext";
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import MobileFriendlyRoundedIcon from '@mui/icons-material/MobileFriendlyRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import PriceCheckRoundedIcon from '@mui/icons-material/PriceCheckRounded';
import RequestPageRoundedIcon from '@mui/icons-material/RequestPageRounded';
import DataSaverOffRoundedIcon from '@mui/icons-material/DataSaverOffRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';

const Sidebar = () => {
  const { setAdminAuthenticated } = useContext(UserContext);
  const logout = () => {
    localStorage.removeItem("adminToken");
    setAdminAuthenticated(false);
  }
  return (
    <div className="sidebaradmin">
      <div className="top">
        <Link to="/adminpanel" style={{ textDecoration: "none" }}>
          <span className="logo">ADMIN PANEL</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <li>
            <Link to="/adminpanel" style={{ textDecoration: "none" }}>            <span>  < AdminPanelSettingsRoundedIcon className="icon" /> Dashboard</span>
            </Link>
          </li>
          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <MobileFriendlyRoundedIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/easypaisa" style={{ textDecoration: "none" }}>
            <li>
              <LocalAtmRoundedIcon className="icon" />
              <span>Easypisa User</span>
            </li>
          </Link>
          <Link to="/cryptoUser" style={{ textDecoration: "none" }}>
            <li>
              <LocalAtmRoundedIcon className="icon" />
              <span>Crypto User</span>
            </li>
          </Link>
          <Link to="/rejecteduser" style={{ textDecoration: "none" }}>
            <li>
              <RemoveCircleRoundedIcon className="icon" />
              <span>Rejected Users</span>
            </li>
          </Link>
          <Link to="/todayApproved" style={{ textDecoration: "none" }}>
            <li>
              <CalendarMonthRoundedIcon className="icon" />
              <span>Today Approved</span>
            </li>
          </Link>

          <Link to="/pending" style={{ textDecoration: "none" }}>
            <li>
              <AutorenewRoundedIcon className="icon" />
              <span>Pending Users</span>
            </li>
          </Link>


          <Link to="/withdrwa" style={{ textDecoration: "none" }}>
            <li>
              <RequestPageRoundedIcon className="icon" />
              <span>Withdrwa Request</span>
            </li>
          </Link>
          <Link to="/salaryRequest" style={{ textDecoration: "none" }}>
            <li>
              <RequestPageRoundedIcon className="icon" />
              <span>Salary Request</span>
            </li>
          </Link>
          <Link to="/ApprovedWithdrwa" style={{ textDecoration: "none" }}>
            <li>
              <PriceCheckRoundedIcon className="icon" />
              <span>Approved Withdrwa</span>
            </li>
          </Link>

          <Link to="/rejectwithdrwa" style={{ textDecoration: "none" }}>
            <li>
              <CancelPresentationRoundedIcon className="icon" />
              <span>Reject Withdrwa</span>
            </li>
          </Link>



          <Link to='/products' style={{ textDecoration: "none" }} >
            <li>
              <LocalShippingIcon className="icon" />
              <span>Products</span>
            </li>
          </Link>
          <p className="title">__________</p>
         



          <Link to="/accounts" style={{ textDecoration: "none" }}>
            <li>
              <CreditCardIcon className="icon" />
              <span>Settings</span>
            </li>
          </Link>
          <Link to="/adminabout" style={{ textDecoration: "none" }}>
            <li>
              <DataSaverOffRoundedIcon className="icon" />
              <span>About </span>
            </li>
          </Link>
          <Link to="/withdrwa_limits" style={{ textDecoration: "none" }}>
          <li>
            <PriceCheckRoundedIcon className="ico"  />
            <span>Withdrwa Limts</span>
          </li>
          </Link>

          <Link to="/commission" style={{ textDecoration: "none" }}>
          <li>
            <RemoveCircleRoundedIcon className="ico"  />
            <span>Commission</span>
          </li>
          </Link>
          <Link to="/levels" style={{ textDecoration: "none" }}>
          <li>
            <MobileFriendlyRoundedIcon className="ico" style={{margin:'5px 5px 0 0'}} />
            <span>Levels</span>
          </li>
          </Link>
       
          <Link to="/accountsetting" style={{ textDecoration: "none" }}>
            <li>
              <SettingsSuggestRoundedIcon className="icon" />
              <span>Account Setting </span>
            </li>
          </Link>
          <li onClick={logout}>
            <ExitToAppIcon className="icon" />
            <a href="/adminlogin">Logout</a>          </li>
        </ul>
      </div>

    </div>
  );
};

export default Sidebar;
