import React, { useEffect, useState } from 'react';
import "../Homepage/home.scss";
import Sidebar from "../SideBarSection/Sidebar";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

const SalaryRequest = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = data.filter(user =>
        user.id.toString().includes(searchTerm.toLowerCase()) ||
        user.account_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.account_number.toString().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        axios.get(`${import.meta.env.VITE_API_BASE_URL}/all-salary-requests`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the withdrawal requests!', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const approveRequest = (userId, requestId, amount) => {

        if (!userId || !requestId || !amount) {
            console.error('User ID, request ID, and amount are required');
            return;
        }

        axios.post(`${import.meta.env.VITE_API_BASE_URL}/approve-salary-request`, { userId, requestId, amount })
            .then(response => {
                setData(data.filter(item => item.id !== requestId)); // Update the state to remove the approved item based on request ID
            })
            .catch(error => {
                console.error('There was an error approving the request!', error);
            });
    };

    const rejectRequest = (userId, requestId) => {

        if (!userId || !requestId) {
            console.error('User ID and request ID are required');
            return;
        }

        axios.post(`${import.meta.env.VITE_API_BASE_URL}/reject-salary-request`, { userId, requestId })
            .then(response => {
                console.log(response.data);
                setData(data.filter(item => item.id !== requestId)); // Update the state to remove the rejected item based on request ID
            })
            .catch(error => {
                console.error('There was an error rejecting the request!', error);
            });
    };

   

  
    return (
        <div className="home">
            <Sidebar />
            <div className="homeContainer">
                <div className="listContainer">
                    <input
                        className='search-bar'
                        type="text"
                        placeholder="Search..."
                        onChange={handleSearch}
                    />
                    {isLoading ? (
 <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
 <Spinner animation="border" role="status">
     <span className="visually-hidden">Loading...</span>
 </Spinner>
</div>
                    ) : (
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr className="table-primary">
                                    <th className='bg-primary text-center text-light' style={{ width: '3%', }}>UID</th>
                                <th className='bg-primary text-center text-light' style={{ width: '4%', }}>User Name</th>

                                    <th className='bg-primary text-center text-light' style={{ width: '3%', } }>Amount</th>
                                    <th className='bg-primary text-center text-light' style={{ width: '3%', } }>Team</th>

                                        <th className='bg-primary text-center text-light ' style={{ width: '5%', }}>Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData.map(row => (
                                        <tr key={row.id}>
                                                                                        <td>{row.user_id}</td>

                                            <td>{row.name}</td>

                                            <td>{row.amount}</td>

                                            <td>{row.team}</td>
                                           
                                            <td>
                                                <button
                                                  className='btn btn-success btn-sm mr-2'
                                                    onClick={() => approveRequest(row.user_id, row.id, row.amount)}
                                                >
                                                    Approve
                                                </button>
                                                <button
                                                className='btn btn-danger btn-sm'
                                                    onClick={() => rejectRequest(row.user_id, row.id)}
                                                >
                                                    Reject
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SalaryRequest;
